import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import QuarklycommunityKitCarousel from "./QuarklycommunityKitCarousel";
import { projectBy } from "data/projects";
const defaultProps = {
  showHead: false,
  showLink: false,
  showText: false,
  autoPlay: true,
  functionProp: "ease-in-out",
  autoPlayIntervalProp: "5s",
  slidesProp: "4",
};
const overrides = {};

const ProjectCarousel = (props) => {
  const { children, rest } = useOverrides(props, overrides, defaultProps);

  const images = projectBy(props.service, props.project)?.images ?? [];

  return (
    <QuarklycommunityKitCarousel
      {...{ ...rest, ...{ slidesProp: images.length } }}
    >
      <Override slot="Point Icon :active" color="--secondary" opacity="0.8" />
      <Override slot="Point Icon" opacity="0.9" />
      <Override slot="Point Icon 1" opacity="0.8" />

      {images.map((e) => {
        const index = images.indexOf(e);
        return (
          <Override
            object-fit="cover"
            key={e}
            slot={`Slide Image${index === 0 ? "" : ` ${index}`}`}
            src={e}
          />
        );
      })}
      {children}
    </QuarklycommunityKitCarousel>
  );
};

Object.assign(ProjectCarousel, {
  ...QuarklycommunityKitCarousel,
  defaultProps,
  overrides,
});
export default ProjectCarousel;
