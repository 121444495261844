import React from "react";
import { useOverrides } from "@quarkly/components";
import { Section } from "@quarkly/widgets";
import TabsHeader from "./TabsHeader";
import ServiceProjects from "./ServiceProjects";
import QuarklycommunityKitTabsFull from "./QuarklycommunityKitTabsFull";
const defaultProps = {};
const overrides = {
  quarklycommunityKitTabsFull: {
    kind: "QuarklycommunityKitTabsFull",
    props: {
      orientation: "horizontal",
      defaultTab: "branding",
    },
  },
  tabsHeader: {
    kind: "TabsHeader",
    props: {},
  },
  serviceProjects: {
    kind: "ServiceProjects",
    props: {},
  },
};

const OurWorks = (props) => {
  const { override, children, rest } = useOverrides(
    props,
    overrides,
    defaultProps
  );
  return (
    <Section {...rest}>
      <QuarklycommunityKitTabsFull {...override("quarklycommunityKitTabsFull")}>
        <TabsHeader {...{ ...override("tabsHeader") }} />
        <ServiceProjects {...{ ...override("serviceProjects") }} />
      </QuarklycommunityKitTabsFull>
      {children}
    </Section>
  );
};

Object.assign(OurWorks, { ...Section, defaultProps, overrides });
export default OurWorks;
