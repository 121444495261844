import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Section } from "@quarkly/widgets";
import QuarklycommunityKitCounter from "./QuarklycommunityKitCounter";
const defaultProps = {
	"padding": "100px 0 120px 0",
	"background": "--color-primary",
	"color": "#000000",
	"sm-padding": "80px 0 90px 0",
	"quarkly-title": "Statistics-5"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 10px 0px",
			"color": "--secondary",
			"children": "Our stats"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--light",
			"font": "--headline2",
			"children": "The Creative Team you want for your project"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "80px 0px 0px 0px",
			"display": "grid",
			"grid-template-columns": "repeat(4, 1fr)",
			"md-grid-template-columns": "repeat(2, 1fr)",
			"md-grid-template-rows": "auto",
			"md-margin": "50px 0px 0px 0px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"border-width": "0 0 0 1px",
			"border-style": "solid",
			"border-color": "rgba(88, 109, 142, 0.5)",
			"padding": "0px 0px 0px 25px",
			"md-margin": "0px 0px 25px 0px",
			"sm-padding": "0px 0px 0px 18px"
		}
	},
	"quarklycommunityKitCounter": {
		"kind": "QuarklycommunityKitCounter",
		"props": {
			"border-color": "--color-light",
			"color": "--light",
			"text-align": "left",
			"endProp": "2020",
			"startProp": "2020"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "15px 0px 0px 0px",
			"color": "#c0cad8",
			"font": "--lead",
			"sm-margin": "8px 0px 0px 0px",
			"children": "Founded"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"border-width": "0 0 0 1px",
			"border-style": "solid",
			"border-color": "rgba(88, 109, 142, 0.5)",
			"padding": "0px 0px 0px 25px",
			"md-margin": "0px 0px 25px 0px",
			"sm-padding": "0px 0px 0px 18px"
		}
	},
	"quarklycommunityKitCounter1": {
		"kind": "QuarklycommunityKitCounter",
		"props": {
			"border-color": "--color-light",
			"color": "--light",
			"text-align": "left",
			"endProp": "5",
			"startTrigger": "onViewport",
			"durationProp": "1s"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "15px 0px 0px 0px",
			"color": "#c0cad8",
			"font": "--lead",
			"sm-margin": "8px 0px 0px 0px",
			"children": "Emplovees"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"border-width": "0 0 0 1px",
			"border-style": "solid",
			"border-color": "rgba(88, 109, 142, 0.5)",
			"padding": "0px 0px 0px 25px",
			"sm-padding": "0px 0px 0px 18px"
		}
	},
	"quarklycommunityKitCounter2": {
		"kind": "QuarklycommunityKitCounter",
		"props": {
			"border-color": "--color-light",
			"color": "--light",
			"text-align": "left",
			"startTrigger": "onViewport",
			"endProp": "100",
			"textAfter": "+",
			"durationProp": "5s",
			"delayProp": "2s"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "15px 0px 0px 0px",
			"color": "#c0cad8",
			"font": "--lead",
			"sm-margin": "8px 0px 0px 0px",
			"children": "Projects"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"border-width": "0 0 0 1px",
			"border-style": "solid",
			"border-color": "rgba(88, 109, 142, 0.5)",
			"padding": "0px 0px 0px 25px",
			"sm-padding": "0px 0px 0px 18px"
		}
	},
	"quarklycommunityKitCounter3": {
		"kind": "QuarklycommunityKitCounter",
		"props": {
			"border-color": "--color-light",
			"color": "--light",
			"text-align": "left",
			"startTrigger": "onViewport",
			"endProp": "10",
			"textAfter": "+",
			"delayProp": "10s"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "15px 0px 0px 0px",
			"color": "#c0cad8",
			"font": "--lead",
			"sm-margin": "8px 0px 0px 0px",
			"children": "Countries"
		}
	}
};

const Stats = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Text {...override("text")} />
		<Text {...override("text1")} />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<QuarklycommunityKitCounter {...override("quarklycommunityKitCounter")} />
				<Text {...override("text2")} />
			</Box>
			<Box {...override("box2")}>
				<QuarklycommunityKitCounter {...override("quarklycommunityKitCounter1")} />
				<Text {...override("text3")} />
			</Box>
			<Box {...override("box3")}>
				<QuarklycommunityKitCounter {...override("quarklycommunityKitCounter2")} />
				<Text {...override("text4")} />
			</Box>
			<Box {...override("box4")}>
				<QuarklycommunityKitCounter {...override("quarklycommunityKitCounter3")} />
				<Text {...override("text5")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Stats, { ...Section,
	defaultProps,
	overrides
});
export default Stats;