import React from "react";
import { useOverrides } from "@quarkly/components";
import { Image, Text, Box } from "@quarkly/widgets";
const defaultProps = {
	"width": "100%",
	"display": "flex",
	"padding": "7px 7px 40px 7px",
	"border-radius": "24px",
	"align-items": "center",
	"flex-direction": "column",
	"border-width": "1px",
	"border-style": "solid",
	"border-color": "--color-lightD2",
	"justify-content": "center",
	"background": "--color-light"
};
const overrides = {
	"image": {
		"kind": "Image",
		"props": {
			"border-radius": "16px",
			"margin": "0px 0px 32px 0px",
			"max-width": "100%",
			"md-width": "100%",
			"width": "100%",
			"height": "278px",
			"object-fit": "cover"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column",
			"padding": "0px 30px 0px 30px",
			"align-items": "flex-start",
			"justify-content": "flex-start"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 8px 0px",
			"font": "--headline3"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 48px 0px",
			"color": "--darkL2",
			"font": "--base"
		}
	}
};

const MembershipItem = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const image = override("image");
	const title = override("text");
	const details = override("text1");
	image.src = props.src;
	title.children = props.title;
	details.children = props.details;
	return <Box {...rest}>
		<Image {...image} />
		<Box {...override("box")}>
			<Text {...title} />
			<Text {...details} />
		</Box>
		{children}
	</Box>;
};

Object.assign(MembershipItem, { ...Box,
	defaultProps,
	overrides
});
export default MembershipItem;