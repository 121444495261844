import React from "react";
import { useOverrides } from "@quarkly/components";
import { Icon, LinkBox, Box } from "@quarkly/widgets";
import { FaFacebook, FaInstagram, FaTwitterSquare } from "react-icons/fa";
const defaultProps = {
  "min-width": "10px",
  "min-height": "10px",
  display: "flex",
  "grid-template-columns": "repeat(5, 1fr)",
  "grid-gap": "16px 24px",
  "md-align-self": "flex-start",
  "justify-items": "start",
  "justify-content": "center",
  "lg-justify-content": "flex-end",
};
const links = {
  fb: {
    url: "https://https://www.facebook.com/letacombrands/",
    category: "fa",
    icon: FaFacebook,
  },
  instagram: {
    url: "https://www.instagram.com/letacom_graphics/?hl=en",
    category: "fa",
    icon: FaInstagram,
  },
  x: {
    url: "https://www.instagram.com/letacom_graphics/?hl=en",
    category: "fa",
    icon: FaTwitterSquare,
  },
};
const overrides = {
  linkBox: {
    kind: "LinkBox",
    props: {
      target: "_blank",
    },
  },
  icon: {
    kind: "Icon",
    props: {
      size: "24px",
      color: "#c3c8d0",
      "hover-color": "--lightD2",
      transition: "background-color 1s ease 0s",
    },
  },
  linkBox1: {
    kind: "LinkBox",
    props: {
      href: "/",
    },
  },
  icon1: {
    kind: "Icon",
    props: {
      category: "fa",
      icon: FaInstagram,
      size: "24px",
      color: "#c3c8d0",
      "hover-color": "--lightD2",
      transition: "background-color 1s ease 0s",
    },
  },
  linkBox2: {
    kind: "LinkBox",
    props: {
      href: "/",
    },
  },
  icon2: {
    kind: "Icon",
    props: {
      category: "fa",
      icon: FaTwitterSquare,
      size: "24px",
      color: "#c3c8d0",
      "hover-color": "--lightD2",
      transition: "background-color 1s ease 0s",
    },
  },
};

const SocialMediaLinks = (props) => {
  const { override, children, rest } = useOverrides(
    props,
    overrides,
    defaultProps
  );
  const fbLink = override("linkBox");
  const instaLink = override("linkBox");
  const xLink = override("linkBox");
  const fbIcon = override("icon");
  const instaIcon = override("icon");
  const xIcon = override("icon");
  fbLink.href = fbLink.url;
  instaLink.href = instaLink.url;
  xLink.href = xLink.url;
  fbIcon.category = links.fb.category;
  fbIcon.icon = links.fb.icon;
  instaIcon.category = links.instagram.category;
  instaIcon.icon = links.instagram.icon;
  xIcon.category = links.x.category;
  xIcon.icon = links.x.icon;
  return (
    <Box {...rest}>
      <LinkBox {...fbLink}>
        <Icon {...fbIcon} />
      </LinkBox>
      <LinkBox {...instaLink}>
        <Icon {...instaIcon} />
      </LinkBox>
      <LinkBox {...xLink}>
        <Icon {...xIcon} />
      </LinkBox>
      {children}
    </Box>
  );
};

Object.assign(SocialMediaLinks, { ...Box, defaultProps, overrides });
export default SocialMediaLinks;
