import React from "react";
import { useOverrides } from "@quarkly/components";
import { Image, Box, Text } from "@quarkly/widgets";
const defaultProps = {
	"position": "relative",
	"display": "flex",
	"flex-direction": "column"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"width": "100%",
			"height": "auto",
			"overflow-x": "hidden",
			"overflow-y": "hidden",
			"position": "relative",
			"padding": "100% 0px 0px 0px",
			"border-radius": "16px"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://images.unsplash.com/photo-1554651802-57f1d69a4944?auto=format&fit=crop&w=500&q=80",
			"border-radius": "16px",
			"width": "100%",
			"object-fit": "cover",
			"position": "absolute",
			"top": 0,
			"left": 0,
			"bottom": 0,
			"right": 0,
			"max-height": "100%"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"position": "absolute",
			"width": "100%",
			"height": "auto",
			"background": "linear-gradient(180deg, #0029FF 0%, rgba(255, 0, 229, 0.24) 100%)",
			"opacity": ".3",
			"bottom": "0px",
			"top": "0px",
			"right": "0px",
			"left": "0px",
			"border-radius": "16px"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"padding": "0px 20px 0px 20px",
			"margin": "0px 0px 16px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "24px 0px 0px 0px",
			"font": "--headline3",
			"display": "block",
			"text-align": "center",
			"color": "--primary"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "16px 0px 0px 0px",
			"font": "normal 500 14px/1.2 --fontFamily-sans",
			"display": "block",
			"text-align": "center",
			"color": "--secondary",
			"letter-spacing": "2.8px"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "16px 0px 0px 0px",
			"font": "--base",
			"display": "block",
			"text-align": "center",
			"color": "--primary"
		}
	}
};

const TeamItem = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const image = override("image");
	const name = override("text");
	const title = override("text1");
	const description = override("text2");
	image.src = props.src;
	name.children = props.name;
	title.children = props.title;
	description.children = props.description;
	return <Box {...rest}>
		<Box {...override("box")}>
			<Image {...image} />
			<Box {...override("box1")} />
		</Box>
		<Box {...override("box2")}>
			<Text {...name} />
			<Text {...title} />
			<Text {...description} />
		</Box>
		{children}
	</Box>;
};

Object.assign(TeamItem, { ...Box,
	defaultProps,
	overrides
});
export default TeamItem;