import React from "react";
import theme from "theme";
import { Theme, Span } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Empowering Success: Letacom's Comprehensive Service Spectrum for Digital Excellence
			</title>
			<meta name={"description"} content={"Explore Letacom's comprehensive service offerings, ranging from Advertising, Digital, Design, and Interactive Services to Mobile Digital Content Solutions, Creative Project Management, and Software Solutions. Elevate your business with tailored solutions, strategic expertise, and innovative approaches for a digital transformation that leaves a lasting impact."} />
			<meta property={"og:description"} content={"Unlock the power of innovation with Letacom's comprehensive services – from Advertising and Digital Solutions to Design, Interactive Services, Mobile Content Solutions, Creative Project Management, and cutting-edge Software Solutions. Elevate your business with transformative strategies and tailor-made solutions for a digital landscape that defines excellence."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65881d791d712a00232d1bea/images/abstract-shapes-exploding-chaotic-colorful-motion-generated-by-ai.jpg?v=2024-01-19T11:13:19.513Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65881d791d712a00232d1bea/images/1%20copy.png?v=2024-01-04T01:13:56.844Z"} type={"image/x-icon"} />
		</Helmet>
		<Components.Header />
		<Components.ServicesHero>
			<Override slot="text" color="--secondary">
				Excellence in creativity{" "}
			</Override>
			<Override slot="text1">
				Striving for perfection in everything we do
				<Span
					color="--secondary"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					.
				</Span>
				{" "}Creative services for{" "}
				<Span
					color="--light"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					font="italic 900 72px/86.400002px -apple-system, system-ui, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, sans-serif"
				>
					your
				</Span>
				{" "}Business{" "}
				<Span
					color="--secondary"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					.
				</Span>
			</Override>
		</Components.ServicesHero>
		<Components.ServicesSection background="--color-light" />
		<Components.LetacomFooter />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65881d791d712a00232d1be8"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});