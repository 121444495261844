export default [
  {
    project: "we-deliver",
    title: "WeDeliver",
    year: "2023",
    description: "WeDeliver",
    brand: "WeDeliver",
    service: "Logos",
    images: [
      "https://i.postimg.cc/Bv4BdBnt/00.jpg",
      "https://i.postimg.cc/ZRqFqY3S/1.jpg",
      "https://i.postimg.cc/28WdyKqV/10.jpg",
      "https://i.postimg.cc/yN10f6LZ/11.jpg",
      "https://i.postimg.cc/HL6XFGNP/12.jpg",
      "https://i.postimg.cc/mDhCsXfZ/13.jpg",
      "https://i.postimg.cc/jqmQsbSp/2.jpg",
      "https://i.postimg.cc/FRnbktgJ/3.jpg",
      "https://i.postimg.cc/wvPJTXgX/4.jpg",
      "https://i.postimg.cc/8cJRhytX/5.jpg",
      "https://i.postimg.cc/g2qvqLHp/6.jpg",
      "https://i.postimg.cc/fRhc9fX5/7.jpg",
      "https://i.postimg.cc/YSRzkLvR/8.png",
      "https://i.postimg.cc/MHCytyL6/9.jpg",
    ],
  },
  {
    project: "invalcre-branding",
    title: "Invalcre Brand Identity",
    year: "2022",
    description:
      "Invalcre (Pvt) Limited, a pioneering force in technology-driven value creation, partnered with Letacom to redefine its brand identity. Together, leveraging Letacom's expertise in innovative solutions, the collaboration focused on crafting a dynamic brand image. Letacom's tailored approach and deep understanding of technology seamlessly translated Invalcre's vision into a vibrant visual identity, encompassing logos and associated elements. <br /> <br /> The result? A brand identity that authentically reflects Invalcre's commitment to technological advancement, innovation, and value creation, positioning them at the forefront of their industry.",
    brand: "Invalcre",
    service: "Logos",
    images: [
      "https://i.postimg.cc/2y2PM16Q/0.jpg",
      "https://i.postimg.cc/brz7mxzY/1.jpg",
      "https://i.postimg.cc/W36RmWcf/2.jpg",
    ],
  },
  {
    project: "driveway",
    title: "Driveway Concepts",
    year: "2023",
    description: "Driveway Concepts",
    brand: "Driveway Concepts",
    service: "Logos",
    images: [
      "https://i.postimg.cc/bYBSc3tt/1.jpg",
      "https://i.postimg.cc/13cztHfM/2.jpg",
      "https://i.postimg.cc/0NPbd4LL/3.jpg",
      "https://i.postimg.cc/tgk7r5b7/4.jpg",
      "https://i.postimg.cc/nVgrwryK/5.jpg",
      "https://i.postimg.cc/4xCKnyw4/6.jpg",
      "https://i.postimg.cc/26XyYSVD/7.jpg",
      "https://i.postimg.cc/RZzF48VN/8.jpg",
    ],
  },
  {
    project: "homelock",
    title: "Homelock Security Solutions Logo",
    year: "2023",
    description: "Homelock Security Solutions Logo",
    brand: "Homelock",
    service: "Logos",
    images: [
      "https://i.postimg.cc/g2dZNrhS/00.jpg",
      "https://i.postimg.cc/dVBTXvmq/01.jpg",
    ],
  },
  {
    project: "hotspot-bar",
    title: "Hotspot Bar Logo",
    year: "2022",
    description: "Hotspot Bar Logo",
    brand: "Hotspot Bar",
    service: "Logos",
    images: [
      "https://i.postimg.cc/rmts8LWR/01.jpg",
      "https://i.postimg.cc/5yd0ykq7/02.jpg",
      "https://i.postimg.cc/wMXBmcw6/03.jpg",
    ],
  },
  {
    project: "icasa",
    title: "ICASA Logo",
    year: "2023",
    description: "ICASA Logo",
    brand: "ICASA",
    service: "Logos",
    images: [
      "https://i.postimg.cc/nz8ZG9jM/01.jpg",
      "https://i.postimg.cc/JhHMX5h9/02.jpg",
    ],
  },
  {
    project: "mono-digital",
    title: "New Logo for Mono Digital",
    year: "2023",
    description:
      "The logo features an abstract design that combines elements of digital technology and African culture. The colors used in the logo - white, purple, and orange convey a sense of simplicity, creativity, and energy. The power button and growth graph in the logo represent Mono Digital's ability to help customers achieve digital growth and success. 'Empowering Digital Growth' is a tagline that conveys the key message of the Mono Digital brand and its focus on providing innovative and customer-centric  digital solutions.",
    brand: "Mono Digital",
    service: "Logos",
    images: [
      "https://i.postimg.cc/XqmgrWDM/0.jpg",
      "https://i.postimg.cc/QMJS0RV5/1.jpg",
      "https://i.postimg.cc/FRFpqDJ6/2.jpg",
      "https://i.postimg.cc/brsTYrgH/3.jpg",
      "https://i.postimg.cc/W398PgG1/4.jpg",
      "https://i.postimg.cc/R0BX1MC4/5.jpg",
    ],
  },
  {
    project: "tenson",
    title: "Tenson Food Basket",
    year: "2023",
    description: "Tenson Food Basket",
    brand: "Tenson",
    service: "Logos",
    images: [
      "https://i.postimg.cc/76PXTMFj/00.jpg",
      "https://i.postimg.cc/vBPh7xMT/01.png",
    ],
  },
  //   {
  //     project: "",
  //     title: "",
  //     year: "2023",
  //     description: "",
  //     brand: "",
  //     service: "Logos",
  //     images: importAll(require.context("./../../assets/projects/logos/")),
  //   },
  //   {
  //     project: "",
  //     title: "",
  //     year: "2023",
  //     description: "",
  //     brand: "",
  //     service: "Logos",
  //     images: importAll(require.context("./../../assets/projects/logos/")),
  //   },
  //   {
  //     project: "",
  //     title: "",
  //     year: "2023",
  //     description: "",
  //     brand: "",
  //     service: "Logos",
  //     images: importAll(require.context("./../../assets/projects/logos/")),
  //   },
];
