import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"our-tech-stack"} />
		<Helmet>
			<title>
				Letacom
			</title>
			<meta name={"description"} content={"At Letacom, we specialize in harnessing the power of creative strategies to propel your business forward. Through innovative solutions and imaginative approaches, we help our clients increase brand visibility, elevate products/services, and drive profitability. "} />
			<meta property={"og:title"} content={"Letacom: Your Partner in creative strategies"} />
			<meta property={"og:description"} content={"At Letacom, we specialize in harnessing the power of creative strategies to propel your business forward. Through innovative solutions and imaginative approaches, we help our clients increase brand visibility, elevate products/services, and drive profitability. "} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65881d791d712a00232d1bea/images/1%20copy.png?v=2024-01-04T01:13:56.844Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65881d791d712a00232d1bea/images/1%20copy.png?v=2024-01-04T01:13:56.844Z"} type={"image/x-icon"} />
		</Helmet>
		<Components.Header />
		<Components.LetacomHero url="https://uploads.quarkly.io/65881d791d712a00232d1bea/images/global-business-internet-network-connection-iot-internet-things-business-intelligence-concept-busines-global-network-futuristic-technology-background-ai-generative.jpg?v=2024-01-03T15:22:21.566Z">
			<Override slot="text3">
				What We Use
			</Override>
			<Override slot="text2">
				{" "}We also appreciate and understand the value of traditional development approaches. That’s why we choose technologies that best suit our projects’ and our clients’ need
			</Override>
			<Override slot="text1">
				We utilise the latest cutting edge framework and trends in technology
			</Override>
			<Override slot="text">
				Our Technologies
			</Override>
		</Components.LetacomHero>
		<Components.TechStackItems>
			<Override slot="techStackItem">
				<Override slot="image" src="https://uploads.quarkly.io/65881d791d712a00232d1bea/images/illustrator.png?v=2024-01-03T15:46:28.915Z" />
			</Override>
		</Components.TechStackItems>
		<Components.LetacomFooter />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65881d791d712a00232d1be8"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});