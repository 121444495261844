// const images=importAll(require("./../../assets/projects/advertising/beulabet"));
export default [
  {
    project: "YoNumbers",
    title: "YoNumbers Econet Christmas Campaign",
    year: "2023",
    description: "YoNumbers Econet Christmas Campaign",
    brand: "Econet",
    service: "Advertising",
    images: [
      "https://i.postimg.cc/8PPXrwbD/1.jpg",
      "https://i.postimg.cc/NjZdStvf/2.jpg",
      "https://i.postimg.cc/QdF6ftPy/3.jpg",
    ],
  },
  {
    project: "beef-tallow",
    title: "Beef Fat Branding",
    year: "2023",
    description: "Beef Fat Branding",
    brand: "Zero Fats",
    service: "Advertising",
    images: ["https://i.postimg.cc/bwPxtvPn/img.jpg"],
  },
  {
    project: "beulabet-flyer-campaigns",
    title: "Beulabet Flyer Campaigns",
    year: "2023",
    description: "Beulabet Flyer Campaigns",
    brand: "Beulabet",
    service: "Advertising",
    images: [
      "https://i.postimg.cc/6pZP9q0G/A5-A6beulabetlocation-Flyer.jpg",
      "https://i.postimg.cc/xdS4qQxp/A5-A6beulabetshortflyer-LOYALTYWhatsapp-Group.jpg",
      "https://i.postimg.cc/qMzY5j6w/A5-Beulabet-Signup-Voucher-Promo-Flyer.jpg",
      "https://i.postimg.cc/W3TCYV0q/A5-beulabet-free-Mazowe-Snack.jpg",
      "https://i.postimg.cc/tgtmxc8q/A5-Beula-Load-Shedding-Free.jpg",
      "https://i.postimg.cc/SRK5qGTy/A5-new-Beulabet-Flyer.jpg",
      "https://i.postimg.cc/28MJRh4D/A6-Flyer-Beulabet-Services-Concept2.jpg",
      "https://i.postimg.cc/T2kF0Z1h/A6-Products-Side-A.jpg",
      "https://i.postimg.cc/C17tW24P/A6-Promos-Side-B.jpg",
    ],
  },
  {
    project: "beulabet-social-media",
    title: "Beulabet Social Media Creatives",
    year: "2023",
    description: "Beulabet Social Media Creatives",
    brand: "Beulabet",
    service: "Advertising",
    images: [
      "https://i.postimg.cc/Qtv5x8SH/1.jpg",
      "https://i.postimg.cc/4N6tsTcx/2.jpg",
      "https://i.postimg.cc/bNcb7vs5/3.jpg",
      "https://i.postimg.cc/L8mPs0kB/4.jpg",
      "https://i.postimg.cc/NGrmCXfT/5.jpg",
      "https://i.postimg.cc/pr8jmw1k/6.jpg",
      "https://i.postimg.cc/MptQfbdk/7.jpg",
    ],
  },
  {
    project: "rhodes-university",
    title: "Rhodes University Biotechnology Innovation Centre",
    year: "2023",
    description: "Rhodes University Biotechnology Innovation Centre",
    brand: "Rubic",
    service: "Advertising",
    images: [
      "https://i.postimg.cc/KjyPDcTC/1.png",
      "https://i.postimg.cc/fyjx24jy/2.png",
    ],
  },

  {
    project: "tenson-wedding",
    title: "Tension & Isabel Wedding Invitation",
    year: "2023",
    description: "Tension & Isabel Wedding Invitation",
    brand: "Tension & Isabel",
    service: "Advertising",
    images: [
      "https://i.postimg.cc/rsc4tnXk/1.jpg",
      "https://i.postimg.cc/Hn2y0wd7/2.jpg",
    ],
  },
];

// {
//     project: "",
//     title: "",
//     year: "2023",
//     description: "",
//     brand: "",
//     service: "Advertising",
//     images: importAll(
//       "./../../assets/projects/advertising/beulabet")
//     ),
//   },
