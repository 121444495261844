import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"careers"} />
		<Helmet>
			<title>
				Letacom
			</title>
			<meta name={"description"} content={"At Letacom, we specialize in harnessing the power of creative strategies to propel your business forward. Through innovative solutions and imaginative approaches, we help our clients increase brand visibility, elevate products/services, and drive profitability. "} />
			<meta property={"og:title"} content={"Letacom: Your Partner in creative strategies"} />
			<meta property={"og:description"} content={"At Letacom, we specialize in harnessing the power of creative strategies to propel your business forward. Through innovative solutions and imaginative approaches, we help our clients increase brand visibility, elevate products/services, and drive profitability. "} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65881d791d712a00232d1bea/images/1%20copy.png?v=2024-01-04T01:13:56.844Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65881d791d712a00232d1bea/images/1%20copy.png?v=2024-01-04T01:13:56.844Z"} type={"image/x-icon"} />
		</Helmet>
		<Components.Header />
		<Components.LetacomHero url="https://images.unsplash.com/photo-1626785774625-ddcddc3445e9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000" />
		<Components.Qualifications />
		<Section padding="150px 0 150px 0" background="rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1531482615713-2afd69097998?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) 0% 0% /cover repeat scroll padding-box" sm-padding="80px 0 80px 0" quarkly-title="Product-7">
			<Box min-width="100px" min-height="100px" display="flex" justify-content="flex-end">
				<Box
					min-width="100px"
					min-height="100px"
					width="400px"
					background="--color-secondaryL2"
					padding="50px 35px 50px 35px"
					display="flex"
					align-items="center"
					flex-direction="column"
					md-padding="35px 35px 35px 35px"
					border-color="--color-secondaryL2"
					border-style="solid"
					border-radius="8PX"
				>
					<Text margin="0px 0px 20px 0px" font="normal 600 32px/1.2 --fontFamily-sans" text-align="center">
						Join Our Team
					</Text>
					<Text margin="0px 0px 35px 0px" text-align="center" font="normal 400 18px/1.5 --fontFamily-sansHelvetica">
						If you possess passion, skills, and experience in any of these disciplines and are eager to contribute to a dynamic and innovative environment, we welcome you to explore opportunities at Letacom.
					</Text>
					<Button
						background="--color-primary"
						padding="12px 30px 12px 30px"
						border-radius="25px"
						type="link"
						text-decoration-line="initial"
						href="mailto:recruitment@letacom.com"
						target="_blank"
					>
						JOIN US NOW
					</Button>
				</Box>
			</Box>
		</Section>
		<Components.LetacomFooter />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65881d791d712a00232d1be8"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});