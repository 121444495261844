import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Icon, LinkBox, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import { BsArrowLeftShort, BsDot } from "react-icons/bs";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Letacom
			</title>
			<meta name={"description"} content={"At Letacom, we specialize in harnessing the power of creative strategies to propel your business forward. Through innovative solutions and imaginative approaches, we help our clients increase brand visibility, elevate products/services, and drive profitability. "} />
			<meta property={"og:title"} content={"Letacom: Your Partner in creative strategies"} />
			<meta property={"og:description"} content={"At Letacom, we specialize in harnessing the power of creative strategies to propel your business forward. Through innovative solutions and imaginative approaches, we help our clients increase brand visibility, elevate products/services, and drive profitability. "} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65881d791d712a00232d1bea/images/1%20copy.png?v=2024-01-04T01:13:56.844Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65881d791d712a00232d1bea/images/1%20copy.png?v=2024-01-04T01:13:56.844Z"} type={"image/x-icon"} />
		</Helmet>
		<Section padding="0px 0 0px 0" lg-max-height="none" min-height="100vh" quarkly-title="404-3">
			<Override slot="SectionContent" max-width="none" width="100%" flex-direction="row" />
			<Box
				align-items="flex-start"
				display="flex"
				justify-content="space-between"
				flex-direction="column"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
			>
				<Box
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="flex-start"
					padding="36px 36px 36px 36px"
					width="100%"
					lg-align-items="center"
					lg-justify-content="center"
					lg-padding="24px 24px 24px 24px"
				>
					<Image
						src="https://uploads.quarkly.io/65881d791d712a00232d1bea/images/1.png?v=2023-12-30T13:39:59.244Z"
						display="block"
						width="200px"
						height="70px"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/65881d791d712a00232d1bea/images/1.png?v=2023-12-30T13%3A39%3A59.244Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65881d791d712a00232d1bea/images/1.png?v=2023-12-30T13%3A39%3A59.244Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65881d791d712a00232d1bea/images/1.png?v=2023-12-30T13%3A39%3A59.244Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65881d791d712a00232d1bea/images/1.png?v=2023-12-30T13%3A39%3A59.244Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65881d791d712a00232d1bea/images/1.png?v=2023-12-30T13%3A39%3A59.244Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65881d791d712a00232d1bea/images/1.png?v=2023-12-30T13%3A39%3A59.244Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65881d791d712a00232d1bea/images/1.png?v=2023-12-30T13%3A39%3A59.244Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					padding="36px 36px 36px 36px"
					lg-align-items="center"
					lg-padding="24px 24px 24px 24px"
					lg-justify-content="flex-start"
				>
					<Text font="--headline3" color="--primary" lg-margin="0px 0px 0px 0px">
						404
					</Text>
					<Text
						color="--dark"
						margin="8px 0px 16px -4px"
						sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
						font="--headline1"
						letter-spacing="-0.025em"
						lg-font="normal 700 48px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Page not found
					</Text>
					<Text
						lg-width="80%"
						font="--lead"
						color="--dark"
						margin="0px 0px 30px 0px"
						lg-text-align="center"
					>
						Sorry, we couldn’t find the page you’re looking for.
					</Text>
					<Box display="flex" grid-template-columns="1fr 1fr" grid-gap="8px" lg-align-items="center">
						<LinkBox
							flex-direction="row"
							padding="12px 8px 12px 8px"
							transition="--opacityOut"
							hover-opacity="0.7"
							lg-margin="0px 0px 20px 0px"
							href="/index"
						>
							<Icon category="bs" icon={BsArrowLeftShort} size="24px" margin="4px 4px 0px 0px" />
							<Text margin="0px 0px 0px 0px" font="--lead" color="--dark">
								Back to home
							</Text>
						</LinkBox>
					</Box>
				</Box>
				<Box
					display="flex"
					flex-direction="row"
					align-items="center"
					width="100%"
					background="--color-light"
					padding="36px 24px 36px 36px"
					grid-gap="8px"
					lg-justify-content="center"
					lg-padding="24px 24px 24px 24px"
				>
					<Link href="#" color="#000000" font="--base" text-decoration-line="initial">
						Contact support
					</Link>
					<Icon category="bs" icon={BsDot} size="16px" />
					<Link href="#" color="#000000" font="--base" text-decoration-line="initial">
						Status
					</Link>
					<Icon category="bs" icon={BsDot} size="16px" />
					<Link href="#" color="#000000" font="--base" text-decoration-line="initial">
						Twitter
					</Link>
				</Box>
			</Box>
			<Box width="50%" lg-display="none">
				<Image
					src="https://uploads.quarkly.io/65881d791d712a00232d1bea/images/abstract-paint-splashing-vibrant-colors-liquid-motion-generated-by-ai.jpg?v=2024-01-02T14:32:17.230Z"
					display="block"
					width="100%"
					height="100%"
					object-fit="cover"
					srcSet="https://smartuploads.quarkly.io/65881d791d712a00232d1bea/images/abstract-paint-splashing-vibrant-colors-liquid-motion-generated-by-ai.jpg?v=2024-01-02T14%3A32%3A17.230Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65881d791d712a00232d1bea/images/abstract-paint-splashing-vibrant-colors-liquid-motion-generated-by-ai.jpg?v=2024-01-02T14%3A32%3A17.230Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65881d791d712a00232d1bea/images/abstract-paint-splashing-vibrant-colors-liquid-motion-generated-by-ai.jpg?v=2024-01-02T14%3A32%3A17.230Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65881d791d712a00232d1bea/images/abstract-paint-splashing-vibrant-colors-liquid-motion-generated-by-ai.jpg?v=2024-01-02T14%3A32%3A17.230Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65881d791d712a00232d1bea/images/abstract-paint-splashing-vibrant-colors-liquid-motion-generated-by-ai.jpg?v=2024-01-02T14%3A32%3A17.230Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65881d791d712a00232d1bea/images/abstract-paint-splashing-vibrant-colors-liquid-motion-generated-by-ai.jpg?v=2024-01-02T14%3A32%3A17.230Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65881d791d712a00232d1bea/images/abstract-paint-splashing-vibrant-colors-liquid-motion-generated-by-ai.jpg?v=2024-01-02T14%3A32%3A17.230Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65881d791d712a00232d1be8"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});