import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Image, Structure, Section } from "@quarkly/widgets";
const defaultProps = {
	"sm-padding": "16px 0 16px 0",
	"quarkly-title": "Advantages/Features-29"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"width": "50%",
			"sm-width": "100%",
			"md-margin": "0px 0px 36px 0px",
			"sm-margin": "0px 0px 8px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--headline3",
			"sm-font": "normal 500 18px/1.2 --fontFamily-googleMontserrat",
			"color": "--primary",
			"sm-margin": "0px 0px 8px 0px",
			"text-transform": "uppercase"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"width": "80%",
			"sm-width": "100%",
			"md-width": "100%"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"border-width": "0px 0px 1px 0px",
			"margin": "0px 0px 20px 0px",
			"sm-margin": "0px",
			"width": "fit-content",
			"border-color": "--color-lightD2",
			"border-style": "solid"
		}
	},
	"structure": {
		"kind": "Structure",
		"props": {
			"cells-number-total": "8",
			"cells-number-group": "4"
		}
	},
	"structureOverride": {
		"kind": "Override",
		"props": {
			"slot": "Content",
			"grid-template-columns": "repeat(8, 3fr)",
			"md-grid-template-columns": "repeat(6, 6fr)",
			"margin": "0px 0px 0px 0px",
			"width": "100%"
		}
	},
	"overrideOverride": {
		"kind": "Override",
		"props": {
			"slot": "cell-0"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/js.png?v=2024-01-01T13:49:07.881Z",
			"display": "block",
			"width": "100%",
			"max-width": "88px",
			"max-height": "88px"
		}
	}
};

const TechStackItem = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const images = props.images ?? [];
	const title = override("text");
	const structure = { ...override("structure"),
		...{
			"cells-number-total": images.length
		}
	};
	const structureOverride = { ...override("structureOverride"),
		...{
			"grid-template-columns": `repeat(${images.length},3fr)`
		}
	};
	title.children = props.title;
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			max-width="1440px"
			align-items="flex-start"
			flex-direction="row"
			flex-wrap="wrap"
			md-flex-direction="column"
			sm-min-width="280px"
		/>
		<Box {...override("box")}>
			<Text {...title} />
		</Box>
		<Box {...override("box1")}>
			<Box {...override("box2")}>
				<Structure {...structure}>
					<Override {...structureOverride}>
						{images.map(img => {
							const o = override("overrideOverride");
							const image = override("image");
							o.slot = `cell-${images.indexOf(img)}`;
							image.src = img.url;
							return <Override {...o}>
								<Image {...image} />
							</Override>;
						})}
					</Override>
				</Structure>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(TechStackItem, { ...Section,
	defaultProps,
	overrides
});
export default TechStackItem;