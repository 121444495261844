import React from "react";
import { useOverrides, Override, StackItem, Stack } from "@quarkly/components";
import { Text, Span, Icon, Box, Section } from "@quarkly/widgets";
import { MdArrowDownward } from "react-icons/md";
const defaultProps = {
	"background": "linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/65881d791d712a00232d1bea/images/abstract-paint-splashing-vibrant-colors-liquid-motion-generated-by-ai.jpg?v=2024-01-02T14:32:17.230Z) center/cover",
	"padding": "64px 0",
	"sm-padding": "40px 0",
	"color": "--light",
	"font": "--base"
};
const overrides = {
	"stack": {
		"kind": "Stack",
		"props": {}
	},
	"stackItem": {
		"kind": "StackItem",
		"props": {
			"width": "75%",
			"lg-width": "100%"
		}
	},
	"stackItemOverride": {
		"kind": "Override",
		"props": {
			"slot": "StackItemContent",
			"flex-direction": "column"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"color": "--lightD2",
			"letter-spacing": "1px",
			"text-transform": "uppercase",
			"margin": "0",
			"children": <>
				Excellence in creativity{" "}
			</>
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"font": "--headline1",
			"md-font": "--headline2",
			"margin": "10px 0",
			"children": <>
				Striving for perfection in everything we do
				<Span
					color="--secondary"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					.
				</Span>
				{" "}Creative services for{" "}
				<Span
					color="--light"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					font="italic 900 72px/86.400002px -apple-system, system-ui, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, sans-serif"
				>
					your
				</Span>
				{" "}Business{" "}
				<Span color="--secondary">
					.
				</Span>
			</>
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"text-align": "center",
			"margin": "96px 0 0 0"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "8px 0",
			"text-transform": "uppercase",
			"children": "WHAT WE DO"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "md",
			"margin": "0 auto",
			"icon": MdArrowDownward
		}
	}
};

const ServicesHero = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Stack {...override("stack")}>
			<StackItem {...override("stackItem")}>
				<Override {...override("stackItemOverride")} />
				<Text {...override("text")} />
				<Text {...override("text1")} />
			</StackItem>
		</Stack>
		<Box {...override("box")}>
			<Text {...override("text2")} />
			<Icon {...override("icon")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(ServicesHero, { ...Section,
	defaultProps,
	overrides
});
export default ServicesHero;