import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Section } from "@quarkly/widgets";
import QuarklycommunityKitAnimation from "./QuarklycommunityKitAnimation";
const defaultProps = {
	"animation": "→ Slide In",
	"trigger": "below",
	"test": true,
	"iteration": "once",
	"overflow-x": "hidden"
};
const overrides = {
	"section": {
		"kind": "Section",
		"props": {
			"md-justify-content": "center",
			"background": "--secondaryGradient,url(https://uploads.quarkly.io/65881d791d712a00232d1bea/images/abstract-paint-splashing-vibrant-colors-liquid-motion-generated-by-ai.jpg?v=2024-01-02T14:32:17.230Z) center center/cover no-repeat",
			"sm-padding": "40px 0 40px 0",
			"quarkly-title": "Hero-24",
			"padding": "0px 0 0px 0",
			"md-align-items": "center",
			"min-height": "400px"
		}
	},
	"sectionOverride": {
		"kind": "Override",
		"props": {
			"slot": "SectionContent",
			"max-width": "none",
			"width": "100%",
			"md-align-items": "center",
			"md-justify-content": "center",
			"flex-direction": "row",
			"flex-wrap": "wrap",
			"justify-content": "flex-start",
			"align-items": "center"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"align-items": "flex-start",
			"justify-content": "center",
			"padding": "36px 48px 56px 48px",
			"flex-direction": "column",
			"lg-width": "50%",
			"md-width": "100%",
			"sm-width": "100%",
			"width": "50%"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"text-transform": "uppercase",
			"letter-spacing": "2px",
			"margin": "0px 0px 13px 0px",
			"color": "--lightD1",
			"font": "normal 600 20px/1.5 --fontFamily-googleMontserrat",
			"lg-width": "100%",
			"lg-text-align": "center",
			"children": "OUR WORK"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"lg-width": "100%",
			"lg-text-align": "center",
			"sm-font": "normal 700 32px/1.2 \"Source Sans Pro\", sans-serif",
			"margin": "0px 0px 17px 0px",
			"color": "--light",
			"font": "--headline2",
			"children": "Featured Projects"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"color": "--light",
			"font": "normal 500 16px/1.5 --fontFamily-googleMontserrat",
			"lg-width": "100%",
			"lg-text-align": "center",
			"margin": "0px 0px 0px 0px",
			"children": "Discover excellence in our Featured Projects showcase, where innovation meets impact. Explore the success stories that define Letacom's commitment to transformative solutions."
		}
	}
};

const FeaturedProjectsHero = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <QuarklycommunityKitAnimation {...rest}>
		<Section {...override("section")}>
			<Override {...override("sectionOverride")} />
			<Box {...override("box")}>
				<Text {...override("text")} />
				<Text {...override("text1")} />
				<Text {...override("text2")} />
			</Box>
		</Section>
		{children}
	</QuarklycommunityKitAnimation>;
};

Object.assign(FeaturedProjectsHero, { ...QuarklycommunityKitAnimation,
	defaultProps,
	overrides
});
export default FeaturedProjectsHero;