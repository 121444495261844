import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Section } from "@quarkly/widgets";
import FaqItem from "./FaqItem";
const defaultProps = {
	"padding": "100px 0 100px 0",
	"background": "#ffffff",
	"quarkly-title": "FAQ-9"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 70px 0px",
			"font": "normal 600 42px/1.2 --fontFamily-sans",
			"color": "--primary",
			"sm-margin": "0px 0px 50px 0px",
			"children": "Frequently asked questions"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"grid-template-columns": "1fr",
			"grid-gap": "50px 50px",
			"lg-grid-template-columns": "repeat(2, 1fr)",
			"md-grid-template-columns": "1fr",
			"sm-grid-gap": "35px 0",
			"flex-direction": "column"
		}
	},
	"faqItem": {
		"kind": "FaqItem",
		"props": {}
	}
};
const items = [{
	question: "What industries do you specialize in for your consulting services?",
	answer: "Letacom specializes in providing consulting services across various industries, including but not limited to technology, e-commerce, healthcare, finance, entertainment, and retail. Our adaptable strategies cater to diverse business sectors."
}, {
	question: "Can you provide examples of your successful projects?",
	answer: "Certainly! We have an extensive portfolio showcasing successful projects across advertising campaigns, web and mobile app designs, branding initiatives, software development, and more. Reach out to us, and we'll be delighted to share our case studies."
}, {
	question: "How do you ensure data security and confidentiality?",
	answer: "At Letacom, we prioritize the security and confidentiality of our clients' data. We implement robust security measures, including encryption protocols, restricted access controls, and compliance with industry-standard security practices to safeguard sensitive information."
}, {
	question: "What is your approach to handling complex projects?",
	answer: "Our approach involves a meticulous planning phase, breaking down complex projects into manageable tasks. We collaborate closely with clients, maintain transparency, and provide regular progress updates to ensure project milestones are met efficiently."
}, {
	question: "Do you offer ongoing support after project completion?",
	answer: "Absolutely! Our commitment extends beyond project completion. We provide continuous support, addressing post-launch concerns, resolving issues, and offering maintenance services to ensure a seamless user experience."
}, {
	question: "How flexible are your payment options?",
	answer: "We offer flexible payment models tailored to suit clients' preferences. Our payment options include Time & Materials Pricing, Fixed Price Contracts, and customizable plans that align with evolving project requirements and budgets."
}, {
	question: "Can you integrate third-party applications into your solutions?",
	answer: "Yes, we specialize in integrating third-party applications seamlessly into our solutions. Whether it's CRM systems, payment gateways, or any other software, we ensure compatibility and functionality to meet our clients' needs."
}, {
	question: "How do you handle feedback during the development process?",
	answer: "Feedback is an integral part of our development process. We encourage open communication, welcome client feedback at every stage, and iteratively refine our solutions to align with their vision and requirements."
}, {
	question: "What sets Letacom apart from other consulting firms?",
	answer: "Letacom stands out due to our innovative solutions, dedication to quality, client-centric approach, and a team of passionate professionals committed to delivering exceptional results while maintaining transparency and reliability."
}, {
	question: "Do you provide training for using the solutions developed?",
	answer: "Yes, we offer comprehensive training programs to equip clients and their teams with the necessary skills to effectively utilize and manage the solutions we develop, ensuring a smooth transition and successful implementation."
}]; // {
// 	question:"",
// 	answer:"",
// },

const FaqsList = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Text {...override("text")} />
		<Box {...override("box")}>
			{items.map(e => {
				const item = { ...override("faqItem"),
					...e
				};
				return <FaqItem {...item} />;
			})}
		</Box>
		{children}
	</Section>;
};

Object.assign(FaqsList, { ...Section,
	defaultProps,
	overrides
});
export default FaqsList;