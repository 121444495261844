import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Section } from "@quarkly/widgets";
import ServiceItem from "./ServiceItem";
const defaultProps = {
	"padding": "60px 0 90px 0",
	"sm-padding": "40px 0",
	"min-height": "600px",
	"sm-min-height": "auto",
	"background": "--color-light",
	"display": "flex",
	"lg-padding": "45px 0 45px 0",
	"quarkly-title": "Advantages/Features-26"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "100%",
			"flex-direction": "column",
			"align-items": "center",
			"lg-width": "100%",
			"sm-margin": "0px 0px 40px 0px",
			"sm-padding": "0px 0px 0px 0px",
			"lg-flex-direction": "row",
			"lg-flex-wrap": "wrap",
			"lg-justify-content": "center"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"margin": "0px 0px 0px 0px",
			"font": "normal 600 40px/1.5 --fontFamily-sans",
			"color": "--primary",
			"width": "100%",
			"md-width": "100%",
			"sm-font": "normal 600 40px/1.2 --fontFamily-sans",
			"text-align": "center",
			"sm-text-align": "left",
			"children": "Explore Our Services"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "8px 0px 0px 0px",
			"font": "--base",
			"text-align": "center",
			"color": "--primary",
			"lg-max-width": "720px",
			"sm-text-align": "left",
			"children": "Learn how we ae ceivering personalization st scal scross sectors"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "grid",
			"width": "100%",
			"grid-template-columns": "repeat(2, 1fr)",
			"grid-gap": "24px",
			"margin": "36px 0px 0px 0px",
			"max-width": "1000px",
			"md-grid-template-columns": "1fr"
		}
	},
	"serviceItem": {
		"kind": "ServiceItem",
		"props": {}
	}
};
const services = [{
	service: "advertising",
	title: "Advertising Services",
	items: ["Market research to analyze trends", "Branding strategies development", "Production and placement of advertisements"]
}, {
	service: "digital",
	title: "Digital Services",
	items: ["Web and mobile app design", "SEO strategies for online presence", "Intranet and digital signage development"]
},, {
	service: "design",
	title: "Design Services",
	items: ["Digital and print design solutions", "Branding and product design expertise", "interactive and UI/UX design capabilities"]
}, {
	service: "interactive",
	title: "Interactive Services",
	items: ["Website design with interactive elements", "Branded games and interactive billboards", "rojects integrating VR/AR technologies"]
},, {
	service: "mobileDigital",
	title: "Mobile Digital Content Solutions",
	items: ["Online gaming platforms offering the ultimate digital playground for players and developers worldwide", "Accessible online (HTML5) and downloadable Android games for subscribed users", "Integration-ready portal with exceptional UX/UI practices", "Comprehensive reporting and analytics for game performance", "Compatibility with smart and feature phones; easily reskinned to suit any brand"]
},, {
	service: "crm",
	title: "Creative Project Management Services",
	items: ["Professional project management expertise from conceptual phase to operation", "Certified consultants ensuring optimal project management and implementation", "efining priorities, risk understanding, and management procedures for clients", "Extensive focus on clear objectives, requirements, and deliverables definition", "Services covering project execution planning, stakeholder engagement, resource planning, scenario planning, risk analysis, design management, team coordination, health & safety protocols, quality control, project close-out, and contract administration"]
},, {
	service: "sss",
	title: "Software Solutions Services",
	items: ["Comprehensive software solutions optimizing business operations", "New ERPs development tailored to specific business needs", "ERPs reselling with access to proven systems", "Training programs for effective ERP software utilization", "Operation SaaS for streamlined operational processes", "Integration services for seamless system functionality", "Maintenance, cloud computing, and support services", "Custom software development addressing specific business challenges", "Data analytics leveraging insights for informed decisions", "Robust security solutions safeguarding systems and data"]
}]; // , {
// 	service: "",
// 	title: "",
// 	items: []
// },

const ServicesSection = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			flex-direction="row"
			flex-wrap="wrap"
			grid-gap="5%"
			max-width="1200px"
			sm-min-width="280px"
		/>
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Box {...override("box1")}>
				{services.map(e => {
					const item = { ...override("serviceItem"),
						...e
					};
					return <ServiceItem {...item} />;
				})}
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(ServicesSection, { ...Section,
	defaultProps,
	overrides
});
export default ServicesSection;