import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Structure } from "@quarkly/widgets";
import ProjectItem from "./ProjectItem";
import QuarklycommunityKitTabsFullBody from "./QuarklycommunityKitTabsFullBody";
import QuarklycommunityKitTabsFullContent from "./QuarklycommunityKitTabsFullContent";
import projectServices from "data/projects/services";
import projects from "data/projects";
const defaultProps = {
  margin: "24px 0px 0px 0px",
  "sm-margin": "8px 0px 0px 0px",
};
const overrides = {
  structure: {
    kind: "Structure",
    props: {
      "cells-number-total": "3",
      "cells-number-group": "3",
    },
  },
  contentOverrride: {
    kind: "QuarklycommunityKitTabsFullContent",
    props: {},
  },
  structureOverride: {
    kind: "Override",
    props: {
      slot: "Content",
      "grid-template-columns": "repeat(3, 1fr)",
      "sm-grid-template-columns": "12fr",
      "md-grid-template-columns": "repeat(2, 1fr)",
      "lg-grid-template-columns": "repeat(2, 1fr)",
      "align-self": "auto",
      width: "100%",
    },
  },
  overrideOverride: {
    kind: "Override",
    props: {
      slot: "cell-0",
    },
  },
  projectItem: {
    kind: "ProjectItem",
    props: {
      project: "we-deliver",
      title: "We deliver project",
      image:
        "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/wedeliver_bluepatternfullwith%20logo.jpg?v=2024-01-17T12:17:03.386Z",
    },
  },
  overrideOverride1: {
    kind: "Override",
    props: {
      slot: "cell-1",
    },
  },
};

const ServiceProjects = (props) => {
  const { override, children, rest } = useOverrides(
    props,
    overrides,
    defaultProps
  );
  console.log(props.service);
  return (
    <QuarklycommunityKitTabsFullBody {...rest}>
      {projectServices.map((e) => {
        const service = projects[e.value] ?? [];
        return service.length === 0 ? undefined : (
          <QuarklycommunityKitTabsFullContent
            {...{
              ...override("contentOverride"),
              ...{
                tabId: e.value,
              },
            }}
          >
            <Structure
              {...{
                ...override("structure"),
                ...{
                  "cells-number-total": service.length,
                },
              }}
            >
              <Override {...override("structureOverride")}>
                {service.map((e) => {
                  return (
                    <Override
                      {...{
                        ...override("overrideOverride"),
                        ...{
                          slot: `cell-${service.indexOf(e)}`,
                        },
                      }}
                    >
                      <ProjectItem {...{ ...override("projectItem"), ...e }} />
                    </Override>
                  );
                })}
              </Override>
            </Structure>
          </QuarklycommunityKitTabsFullContent>
        );
      })}
      {children}
    </QuarklycommunityKitTabsFullBody>
  );
};

Object.assign(ServiceProjects, {
  ...QuarklycommunityKitTabsFullBody,
  defaultProps,
  overrides,
});
export default ServiceProjects;
