import React from "react";
import Index from "pages/index";
import Services from "pages/services";
import AboutUs from "pages/aboutUs";
import Projects from "pages/projects";
import Faqs from "pages/faqs";
import Careers from "pages/careers";
import Project from "pages/project";
import Resources from "pages/resources";
import OurTechStack from "pages/ourTechStack";
import HowWeWork from "pages/howWeWork";
import Contactus from "pages/contactus";
import Page404 from "pages/page404";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        font-family: sans-serif;
    }
`;

export default () => (
  <Router>
    <GlobalStyles />
    <Switch>
      <Route exact path="/" component={Index} />
      <Route exact path="/index" component={Index} />
      <Route exact path="/services" component={Services} />
      <Route exact path="/about-us" component={AboutUs} />
      <Route exact path="/projects" component={Projects} />
      <Route exact path="/faqs" component={Faqs} />
      <Route exact path="/careers" component={Careers} />
      <Route exact path="/project/:service/:project" component={Project} />
      <Route exact path="/resources" component={Resources} />
      <Route exact path="/our-tech-stack" component={OurTechStack} />
      <Route exact path="/how-we-work" component={HowWeWork} />
      <Route exact path="/contactus" component={Contactus} />
      <Route component={Page404} />
    </Switch>
  </Router>
);
