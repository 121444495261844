import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Span, Link, Box, Section } from "@quarkly/widgets";
import QuarklycommunityKitAnimation from "./QuarklycommunityKitAnimation";
import ResponsiveLottie from "./ResponsiveLottie";
import HeroMarquee from "./HeroMarquee";
const defaultProps = {
	// "min-height": "100vh",
	"padding": "0 0 0 0",
	"background": "rgba(0, 0, 0, 0)"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "40%",
			"flex-direction": "column",
			"justify-content": "center",
			"align-items": "flex-start",
			"lg-width": "100%",
			"lg-align-items": "center",
			"sm-margin": "0px 0px 50px 0px",
			"margin": "40px 5% 40px 5%",
			"lg-padding": "24px 60px 24px 60px",
			"sm-padding": "0px 20px 0px 20px",
			"padding": "24px 0px 24px 0px"
		}
	},
	"quarklycommunityKitAnimation": {
		"kind": "QuarklycommunityKitAnimation",
		"props": {
			"animation": "→ Slide In",
			"iteration": "once",
			"duration": "2s",
			"timingFunction": "ease-in",
			"trigger": "onload"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 24px 0px",
			"color": "--dark",
			"font": "--headline1",
			"lg-text-align": "center",
			"sm-font": "normal 700 42px/1.2 \"Source Sans Pro\", sans-serif",
			"md-font": "normal 700 42px/1.2 \"Source Sans Pro\", sans-serif",
			"hover-border-style": "none",
			"hover-border-width": "0 0 2px 0",
			"transition": "all 5s linear 0s",
			"children": <>
				<Span
					color="--secondary"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					transition="all 0.3s ease 0s"
				>
					Your Partner{" "}
					<br />
				</Span>
				In Creative Strategies!
			</>
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 40px 0px",
			"color": "--darkL2",
			"font": "--lead",
			"lg-text-align": "center",
			"children": <>
				At Letacom, we specialize in harnessing the power of creative strategies to propel your business forward. Through innovative solutions and imaginative approaches, we help our clients increase brand visibility, elevate products/services, and drive profitability.{" "}
			</>
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"sm-flex-direction": "column",
			"sm-width": "100%",
			"sm-text-align": "center",
			"align-items": "center",
			"min-width": "55%",
			"align-self": "auto",
			"hover-transform": "translateY(0px)"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "/contactus",
			"padding": "12px 24px 12px 24px",
			"color": "--light",
			"text-decoration-line": "initial",
			"font": "--lead",
			"border-radius": "8px",
			"margin": "0px 16px 0px 0px",
			"transition": "background-color 0.2s ease-in-out 0s",
			"sm-margin": "0px 0px 16px 0px",
			"sm-text-align": "center",
			"background": "--color-secondary",
			"hover-transition": "background-color 0.2s ease-in-out 0s",
			"text-align": "center",
			"width": "100%",
			"hover-background": "none",
			"hover-color": "--secondary",
			"hover-border-style": "solid",
			"hover-border-width": "2px",
			"children": "Let's Talk"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"width": "50%",
			"display": "block",
			"justify-content": "center",
			"overflow-y": "hidden",
			"overflow-x": "hidden",
			"lg-width": "100%",
			"lg-min-height": "400px",
			"background": "none",
			"align-self": "center",
			"md-height": "60%",
			"md-min-height": "55%"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"width": "100%",
			"height": "80%",
			"md-height": "300px",
			"lg-height": "400px"
		}
	},
	"responsiveLottie": {
		"kind": "ResponsiveLottie",
		"props": {
			"path": "https://lottie.host/4d9874be-c62f-433d-8c0c-3b2814cc25cd/U5amQ89cYj.json"
		}
	},
	"heroMarquee": {
		"kind": "HeroMarquee",
		"props": {}
	}
};

const Hero = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			flex-direction="row"
			flex-wrap="wrap"
			max-width="none"
			width="100%"
			sm-padding="72px 0px 0px 0px"
		/>
		<Box {...override("box")}>
			<QuarklycommunityKitAnimation {...override("quarklycommunityKitAnimation")}>
				<Text {...override("text")} />
			</QuarklycommunityKitAnimation>
			<Text {...override("text1")} />
			<Box {...override("box1")}>
				<Link {...override("link")} />
			</Box>
		</Box>
		<Box {...override("box2")}>
			<Box {...override("box3")}>
				<ResponsiveLottie {...override("responsiveLottie")} />
			</Box>
			<HeroMarquee {...override("heroMarquee")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Hero, { ...Section,
	defaultProps,
	overrides
});
export default Hero;