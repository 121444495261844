import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Icon, LinkBox } from "@quarkly/widgets";
import QuarklycommunityKitMarquee from "./QuarklycommunityKitMarquee";
import { BsArrowUpRight } from "react-icons/bs";
const defaultProps = {
	"pauseOnHover": true
};
const overrides = {
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"flex-direction": "row",
			"padding": "0px 16px 0px 16px",
			"href": "/services"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 24px 0px 0px",
			"color": "--primary",
			"children": "BRAND DESIGN"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "bs",
			"icon": BsArrowUpRight,
			"size": "32px",
			"color": "--light",
			"background": "--color-secondary",
			"border-color": "--color-light",
			"border-radius": "50%"
		}
	}
};
const services = [{
	"value": "advertising",
	"label": "Advertising Services"
}, {
	"value": "digital",
	"label": "Digital Services"
}, {
	"value": "design",
	"label": "Design Services"
}, {
	"value": "interactive",
	"label": "Interactive Services"
}, {
	"value": "mobileDigital",
	"label": "Mobile Digital Content Solutions"
}, {
	"value": "crm",
	"label": "Creative Project Management Services"
}, {
	"value": "sss",
	"label": "Software Solutions Services"
}];

const HeroMarquee = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <QuarklycommunityKitMarquee {...rest}>
		<Override slot="Container" padding="16PX 16px 16px 16px" />
		{services.map(e => {
			return <LinkBox {...override("linkBox")}>
				<Text {...{ ...override("text"),
					...{
						children: e.label
					}
				}} />
				<Icon {...override("icon")} />
			</LinkBox>;
		})}
		{children}
	</QuarklycommunityKitMarquee>;
};

Object.assign(HeroMarquee, { ...QuarklycommunityKitMarquee,
	defaultProps,
	overrides
});
export default HeroMarquee;