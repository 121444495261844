import React from 'react';
import atomize from "@quarkly/atomize";
import Select from "react-select";
const selectStyles = {
	control: (base, state) => ({ ...base,
		height: "58px",
		borderRadius: 0,
		borderWidth: "0 0 1px 0",
		borderColor: "#0a064e",
		cursor: "url(https://icons8.com/icon/mlOA4v4RXlTX/cursor),auto",
		fontFamily: "Montserrat",
		fontSize: "16px",
		fontColor: "#0a064e",
		fontWeight: "300",
		fontStyle: "normal",
		lineHeight: "1.5"
	})
};

const LetaSelect = props => {
	const handleChange = selectedOption => {// this.setState({ selectedOption }, () =>
		//   console.log(`Option selected:`, this.state.selectedOption)
		// );
	};

	return <Select
		name={props.name}
		isMulti={props.isMulti}
		placeholder={props.placeholder}
		onChange={handleChange}
		options={props.options}
		styles={selectStyles}
	/>;
};

export default atomize(LetaSelect)({
	name: "LetaSelect",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		// paste here description for your component
		en: "LetaSelect — my awesome component"
	},
	propInfo: {
		// paste here props description for your component
		yourCustomProps: {
			control: "input"
		}
	}
});