import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"how-we-work"} />
		<Helmet>
			<title>
				Letacom
			</title>
			<meta name={"description"} content={"At Letacom, we specialize in harnessing the power of creative strategies to propel your business forward. Through innovative solutions and imaginative approaches, we help our clients increase brand visibility, elevate products/services, and drive profitability. "} />
			<meta property={"og:title"} content={"Letacom: Your Partner in creative strategies"} />
			<meta property={"og:description"} content={"At Letacom, we specialize in harnessing the power of creative strategies to propel your business forward. Through innovative solutions and imaginative approaches, we help our clients increase brand visibility, elevate products/services, and drive profitability. "} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65881d791d712a00232d1bea/images/1%20copy.png?v=2024-01-04T01:13:56.844Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65881d791d712a00232d1bea/images/1%20copy.png?v=2024-01-04T01:13:56.844Z"} type={"image/x-icon"} />
		</Helmet>
		<Components.Header />
		<Components.LetacomHero url="https://uploads.quarkly.io/65881d791d712a00232d1bea/images/office-floor-plan-sketch-drawing-concept.jpg?v=2024-01-03T22:53:10.918Z">
			<Override slot="text3">
				Our Processs
			</Override>
			<Override slot="text2">
				{" "}Our team members are passionate about new technologies and deeply involved in their work, ensuring they understand your developed product.
			</Override>
			<Override slot="text1">
				Understanding the Product
			</Override>
			<Override slot="text">
				How we work
			</Override>
		</Components.LetacomHero>
		<Components.HowWeWorkItems />
		<Components.PaymentModelsItem>
			<Override slot="paymentModelItem">
				<Override slot="icon" />
			</Override>
		</Components.PaymentModelsItem>
		<Components.LetacomFooter />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65881d791d712a00232d1be8"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});