import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box } from "@quarkly/widgets";
const defaultProps = {
	"display": "flex",
	"flex-direction": "column",
	"padding": "24px 24px 24px 24px",
	"border-radius": "8px",
	"border-width": "0px 0px 0px 4px",
	"border-style": "solid",
	"border-color": "--color-secondary",
	"box-shadow": "--m",
	"sm-padding": "24px 24px 24px 24px"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--primary",
			"font": "--headline2",
			"sm-font": "normal 700 28px/1.2 --fontFamily-googleMontserrat"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 5px 0px",
			"font": "normal 400 18px/1.2 --fontFamily-googleMontserrat",
			"color": "--primary"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"align-items": "center",
			"border-width": "0 0 1px 0",
			"border-style": "dashed",
			"border-color": "#bec3ca",
			"margin": "8px 0 6px 0"
		}
	}
};

const QualificationItem = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const text = override("text");
	text.children = props.title ?? '';
	const description = override("text1");
	description.children = props.description ?? '';
	return <Box {...rest}>
		    
		<Text {...text} />
		    
		<Box {...override("box")}>
			      
			<Text {...description} />
			    
		</Box>
		    
		{children}
		  
	</Box>;
};

Object.assign(QualificationItem, { ...Box,
	defaultProps,
	overrides
});
export default QualificationItem;