import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Em, Span, Icon, LinkBox, Box, Section } from "@quarkly/widgets";
import QuarklycommunityKitLottie from "./QuarklycommunityKitLottie";
import QuarklycommunityKitAnimation from "./QuarklycommunityKitAnimation";
import { MdArrowForward } from "react-icons/md";
const defaultProps = {
	"padding": "80px 0 80px 0",
	"sm-padding": "60px 0 60px 0",
	"background": "--color-light",
	"quarkly-title": "About-11"
};
const overrides = {
	"quarklycommunityKitAnimation": {
		"kind": "QuarklycommunityKitAnimation",
		"props": {
			"trigger": "above",
			"animation": "→ Slide In",
			"iteration": "once",
			"test": true
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"width": "100%",
			"display": "flex",
			"flex-direction": "column",
			"md-width": "100%",
			"padding": "0px 128px 0px 128px",
			"lg-padding": "0px 64px 0px 64px",
			"lg-width": "70%",
			"md-padding": "0px 0px 0px 0px"
		}
	},
	"quarklycommunityKitLottie": {
		"kind": "QuarklycommunityKitLottie",
		"props": {
			"path": "https://lottie.host/19690759-0901-4fe1-b8b0-1326d0b91f23/6sZJVHoc99.json",
			"width": "100px",
			"height": "100px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "8px 0px 0px 0px",
			"font": "--headline2",
			"md-margin": "24px 0px 30px 0px",
			"sm-margin": "8px 0px 8px 0px",
			"sm-font": "--headline3",
			"border-color": "--color-secondary",
			"color": "--secondary",
			"text-align": "center",
			"children": "Have a project for us?"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"font": "normal 900 32px/1.2 --fontFamily-googleMontserrat",
			"md-margin": "24px 0px 30px 0px",
			"sm-margin": "8px 0px 16px 0px",
			"sm-font": "--headline3",
			"text-align": "center",
			"children": <Span caret-color="rgb(155, 108, 252)">
				<Em>
					Lets talk about!
				</Em>
			</Span>
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 38px 0px",
			"sm-margin": "0px 0px 16px 0px",
			"color": "--primary",
			"font": "--lead",
			"children": "At Letacom, we thrive on turning visionary projects into impactful realities. Whether you're a startup, SME, or a large enterprise, our comprehensive services cover everything from creative design to robust software solutions. Let's discuss your unique project needs and tailor a solution that aligns with your goals. Your success story awaits – trust in our adaptability, innovation, and commitment to excellence."
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"flex-direction": "row",
			"href": "/contactus"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"background": "--color-primary",
			"color": "--lightD1",
			"margin": "0px 4px 0px 0px",
			"height": "40px",
			"min-width": "56px",
			"text-align": "center",
			"border-radius": "8px 0px 0px 8px",
			"align-self": "auto",
			"display": "flex",
			"align-items": "center",
			"font": "normal 500 16px/1.5 --fontFamily-googleMontserrat",
			"padding": "0px 16px 0px 16px",
			"width": "50%",
			"sm-width": "70%",
			"justify-content": "center",
			"children": "GET STARTED"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "md",
			"icon": MdArrowForward,
			"size": "32px",
			"background": "--color-primary",
			"color": "--lightD1",
			"height": "40px",
			"width": "40px",
			"border-radius": "0px 8px 8px 0px"
		}
	}
};

const ProjectContactUs = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			flex-direction="row"
			md-flex-wrap="wrap"
			max-width="1200px"
			sm-min-width="280px"
			justify-content="center"
		/>
		<QuarklycommunityKitAnimation {...override("quarklycommunityKitAnimation")}>
			<Box {...override("box")}>
				<QuarklycommunityKitLottie {...override("quarklycommunityKitLottie")} />
				<Text {...override("text")} />
				<Text {...override("text1")} />
				<Text {...override("text2")} />
				<LinkBox {...override("linkBox")}>
					<Text {...override("text3")} />
					<Icon {...override("icon")} />
				</LinkBox>
			</Box>
		</QuarklycommunityKitAnimation>
		{children}
	</Section>;
};

Object.assign(ProjectContactUs, { ...Section,
	defaultProps,
	overrides
});
export default ProjectContactUs;