import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Strong, Box, Section } from "@quarkly/widgets";
import WcuItem from "./WcuItem";
import { FaSeedling, FaInfoCircle, FaPlusCircle, FaRegClock, FaBriefcase, FaLaptopCode, FaHeadset, FaWallet, FaPagelines } from "react-icons/fa";
const defaultProps = {
	"padding": "20px 0 90px 0",
	"sm-padding": "40px 0",
	"min-height": "600px",
	"sm-min-height": "auto",
	"display": "flex",
	"lg-padding": "45px 0 45px 0",
	"quarkly-title": "Advantages/Features-18"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "100%",
			"flex-direction": "column",
			"align-items": "center",
			"lg-width": "100%",
			"sm-margin": "0px 0px 40px 0px",
			"sm-padding": "0px 0px 0px 0px",
			"lg-flex-direction": "row",
			"lg-flex-wrap": "wrap",
			"lg-justify-content": "center",
			"sm-justify-content": "flex-start"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "8px 0px 0px 0px",
			"font": "--lead",
			"text-align": "center",
			"color": "--secondary",
			"sm-text-align": "left",
			"children": <Strong
				color="--secondary"
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				user-select="auto"
				pointer-events="auto"
			>
				CREATIVITE STRATEGIES
			</Strong>
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"margin": "4px 0px 12px 0px",
			"font": "--headline2",
			"color": "--primary",
			"width": "100%",
			"md-width": "100%",
			"text-align": "center",
			"sm-text-align": "left",
			"children": "Why Choose us?"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "8px 0px 0px 0px",
			"font": "--lead",
			"text-align": "center",
			"color": "--primary",
			"width": "70%",
			"lg-width": "100%",
			"sm-text-align": "left",
			"children": "Choose us for a collaborative, client-centered approach that guarantees success"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "grid",
			"width": "100%",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "24px",
			"margin": "60px 0px 0px 0px",
			"max-width": "1000px",
			"md-grid-template-columns": "1fr",
			"md-grid-gap": "6px",
			"sm-grid-gap": "32px"
		}
	},
	"wcuItem": {
		"kind": "WcuItem",
		"props": {}
	}
};
const items = [{
	icon: FaSeedling,
	category: "fa",
	title: "Our Ability to Adapt",
	description: "We adapt to your needs and constraints, being flexible and responsive."
}, {
	icon: FaInfoCircle,
	category: "fa",
	title: "Tailor-made Support",
	description: "We offer support throughout your projects, providing advice and guidance."
}, {
	icon: FaPlusCircle,
	category: "fa",
	title: "Strength of Proposal",
	description: "Innovative and creative solutions combined with our expertise."
}, {
	icon: FaRegClock,
	category: "fa",
	title: "Punctuality",
	description: "Commitment to meeting project timelines and sticking to deadlines."
}, {
	icon: FaBriefcase,
	category: "fa",
	title: "Effective Work",
	description: "Thoughtful planning resulting in optimal solutions."
}, {
	icon: FaLaptopCode,
	category: "fa",
	title: "High-Quality Development",
	description: "A highly experienced team with a successful track record of delivering over 100 projects."
}, {
	icon: FaHeadset,
	category: "fa",
	title: "Project Support",
	description: "Active support even after project completion to ensure the best user experience."
}, {
	icon: FaWallet,
	category: "fa",
	title: "Budget Flexibility",
	description: "Adjusting solutions to fit your budget."
}, {
	icon: FaPagelines,
	category: "fa",
	title: "Agile Work Approach",
	description: "Working in scrums and inviting client involvement to align the product vision."
}]; // {
// 	icon:"",
// 	category:"",
// 	title:"",
// 	description:""
// },

const WhyChooseUs = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			flex-direction="row"
			flex-wrap="wrap"
			grid-gap="5%"
			max-width="1200px"
			sm-min-width="280px"
		/>
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
			<Text {...override("text2")} />
			<Box {...override("box1")}>
				{items.map(e => {
					const item = { ...override("wcuItem"),
						...e
					};
					return <WcuItem {...item} />;
				})}
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(WhyChooseUs, { ...Section,
	defaultProps,
	overrides
});
export default WhyChooseUs;