import React, { useState } from "react";
import { useOverrides } from "@quarkly/components";
import QuarklycommunityKitLottie from "./QuarklycommunityKitLottie";
const defaultProps = {
	"isPaused": false,
	"isStopped": false,
	"forceUpdate": true,
	"path": "https://lottie.host/4d9874be-c62f-433d-8c0c-3b2814cc25cd/U5amQ89cYj.json",
	"loop": false,
	"firstFrame": "210",
	"height": "100%"
};
const overrides = {};

const ResponsiveLottie = props => {
	const [loop, setLoop] = useState(false);
	const {
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <QuarklycommunityKitLottie {...{ ...rest,
		...{
			loop: loop,
			path: props.path
		}
	}} onMouseOver={() => setLoop(true)} onMouseOut={() => setLoop(false)}>
		{children}
	</QuarklycommunityKitLottie>;
};

Object.assign(ResponsiveLottie, { ...QuarklycommunityKitLottie,
	defaultProps,
	overrides
});
export default ResponsiveLottie;