import React from "react";
import { useOverrides, Override, StackItem, Stack } from "@quarkly/components";
import { Text, Icon, Box, Section } from "@quarkly/widgets";
import { MdArrowDownward } from "react-icons/md";
const defaultProps = {
	"background": "linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/65881d791d712a00232d1bea/images/composition-with-question-mark-urban-landscape.jpg?v=2024-01-03T13:22:22.328Z) center/cover",
	"padding": "64px 0",
	"sm-padding": "40px 0",
	"color": "--light",
	"font": "--base"
};
const overrides = {
	"stack": {
		"kind": "Stack",
		"props": {}
	},
	"stackItem": {
		"kind": "StackItem",
		"props": {
			"width": "75%",
			"lg-width": "100%"
		}
	},
	"stackItemOverride": {
		"kind": "Override",
		"props": {
			"slot": "StackItemContent",
			"flex-direction": "column"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"color": "--secondary",
			"letter-spacing": "1px",
			"text-transform": "uppercase",
			"margin": "0",
			"children": <>
				Frequently asked questions{" "}
			</>
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"font": "--headline1",
			"md-font": "--headline2",
			"margin": "10px 0",
			"sm-font": "--headline3",
			"children": "Welcome to Letacom's FAQ section!"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"children": "Where we address common inquiries about our services, processes, and more. If you don't find the answers you're looking for here, feel free to reach out to our team directly for further assistance."
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"text-align": "center",
			"margin": "96px 0 0 0"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "8px 0",
			"text-transform": "uppercase",
			"children": "faqs"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "md",
			"margin": "0 auto",
			"icon": MdArrowDownward
		}
	}
};

const FaqsHero = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Stack {...override("stack")}>
			<StackItem {...override("stackItem")}>
				<Override {...override("stackItemOverride")} />
				<Text {...override("text")} />
				<Text {...override("text1")} />
				<Text {...override("text2")} />
			</StackItem>
		</Stack>
		<Box {...override("box")}>
			<Text {...override("text3")} />
			<Icon {...override("icon")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(FaqsHero, { ...Section,
	defaultProps,
	overrides
});
export default FaqsHero;