export default [
  {
    project: "yoNumbers-giveaways",
    title: "YoNumbers Econet Promo Giveaways",
    year: "2024",
    description:
      " In 2024, we facilitated branded giveaways for the YoNumbers Econet Promo in collaboration with Econet Wireless Zimbabwe and Formal Grand Resources Zimbabwe. Our services included branding on various items like t-shirts, caps, bags, phones, water bottles, power banks, key holders, notebooks, pens, and more. Each item was meticulously customized with the promotional graphics and logos, ensuring a cohesive and impactful representation of the campaign. These branded giveaways served as effective promotional tools, reinforcing the campaign's message while offering useful and visually appealing items to the target audience.",
    brand: "Econet",
    service: "Branding",
    images: [
      "https://i.postimg.cc/L6SdnzJ3/1.jpg",
      "https://i.postimg.cc/yxzG3H88/2.jpg",
      "https://i.postimg.cc/c4BpYrYy/Flask.jpg",
      "https://i.postimg.cc/Z5fX72Vw/Keyholder-Opener.jpg",
      "https://i.postimg.cc/43mqrP0s/Powerbank.jpg",
      "https://i.postimg.cc/d3mx8xhm/Staff-Golf-Tshirt.jpg",
      "https://i.postimg.cc/JnXghnmF/Stationery-Artwork.jpg",
      "https://i.postimg.cc/vZ8pvbL1/Tshirt-Artwork.jpg",
      "https://i.postimg.cc/XNwmSpsG/Water-Bottle-Artwork.jpg",
    ],
  },
  {
    project: "mtf",
    title: "Mthenge Security Force Patrol Vehicle Wrap",
    year: "2021",
    description:
      "In 2021, we provided Mthenge Security Force with a sleek vehicle branding solution through a customized wrap. This design, featuring their logo and distinct visuals, enhanced their fleet's visibility and professionalism. The branded vehicles served as moving advertisements, reinforcing Mthenge Security Force's presence and services across various locations throughout the year.",
    brand: "Mthenge Security Force",
    service: "Branding",
    images: [
      "https://i.postimg.cc/tCsqNdqQ/1.png",
      "https://i.postimg.cc/W4vsVQMT/2.png",
      "https://i.postimg.cc/pL8Pg25s/3.png",
      "https://i.postimg.cc/g0Hm2NDR/4.png",
    ],
  },
  {
    project: "tin-pan-vehicle-wrap",
    title: "TinPan Loads Vehicle Wrap",
    year: "2023",
    description:
      "In 2023, we implemented standout vehicle branding for TinPan Loads via custom vehicle wraps. These wraps featured distinctive visuals and the company's logo, effectively elevating the visibility and professional appearance of their fleet. The branded trucks acted as mobile advertisements, promoting TinPan Loads' services and identity as they traversed different routes and locations throughout the year.",
    brand: "TinPan Loads",
    service: "Branding",
    images: ["https://i.postimg.cc/kMb9VJfq/1.jpg"],
  },
  {
    project: "buidlink-Vehicle-Wrap-Artwork",
    title: "Buidlink Vehicle Wrap Artwork",
    year: "2022",
    description:
      "In 2022, we executed striking vehicle branding for Buildlink through a meticulously designed vehicle wrap. This custom artwork adorned their fleet, incorporating the company's logo, colors, and key messaging. The vibrant and professional design enhanced brand visibility, ensuring Buildlink's presence stood out on the roads. This branding initiative not only showcased creativity but also acted as a mobile advertisement, promoting Buildlink's services and identity wherever their vehicles traveled throughout the year.",
    brand: "Buidlink",
    service: "Branding",
    images: [
      "https://i.postimg.cc/PqYNzWMr/1.jpg",
      "https://i.postimg.cc/4d1djXMD/2.jpg",
    ],
  },
  // {
  //   project: "",
  //   title: "",
  //   year: "2023",
  //   description: "",
  //   brand: "",
  //   service: "Branding",
  //   images: importAll("./../../assets/projects/branding/")),
  // },
];
