import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Image, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 80px 0",
	"sm-padding": "60px 0 60px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "60%",
			"flex-direction": "column",
			"justify-content": "flex-start",
			"align-items": "flex-start",
			"lg-align-items": "center",
			"lg-margin": "0px 0px 20px 0px",
			"padding": "0px 10% 0px 0px",
			"lg-width": "100%",
			"md-margin": "0px 0px 30px 0px",
			"lg-padding": "0px 0px 0px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"color": "--secondary",
			"font": "normal 500 12px/14px --fontFamily-googleSourceSansPro",
			"lg-text-align": "center",
			"display": "flex",
			"align-items": "flex-start",
			"text-transform": "uppercase",
			"letter-spacing": "3px",
			"md-margin": "0px 0px 0px 0px",
			"children": "ABOUT US"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--primary",
			"font": "--headline1",
			"lg-text-align": "center",
			"sm-font": "normal 700 32px/1.2 \"Source Sans Pro\", sans-serif",
			"padding": "0px 16px 0px 0px",
			"lg-margin": "0px 0px 48px 0px",
			"sm-margin": "0px 0px 24px 0px",
			"children": "WE DELIVER EXCEPTIONAL CREATIVITY"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "40%",
			"flex-direction": "column",
			"justify-content": "flex-start",
			"align-items": "flex-start",
			"lg-align-items": "flex-start",
			"padding": "0px 0px 32px 16px",
			"lg-width": "100%",
			"lg-flex-direction": "row",
			"lg-flex-wrap": "wrap",
			"lg-justify-content": "flex-start",
			"lg-padding": "0px 0px 0px 0px"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"width": "80%",
			"height": "auto",
			"overflow-x": "hidden",
			"overflow-y": "hidden",
			"position": "relative",
			"transform": "translateY(0px)",
			"transition": "transform 0.2s ease-in-out 0s",
			"hover-transform": "translateY(-10px)",
			"padding": "0px 0px 100% 0px",
			"border-radius": "24px",
			"margin": "0px 0px 34px 0px",
			"lg-width": "50%",
			"lg-margin": "0px 32px 34px 0px",
			"lg-padding": "0px 0px 75% 0px",
			"sm-margin": "0px 0px 24px 0px",
			"sm-width": "100%",
			"sm-padding": "0px 0px 135% 0px"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/marketing-strategy-planning-strategy-concept.jpg?v=2024-01-03T06:49:33.365Z",
			"object-fit": "cover",
			"position": "absolute",
			"display": "block",
			"width": "100%",
			"top": 0,
			"left": 0,
			"right": 0,
			"bottom": 0,
			"min-height": "100%"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"lg-width": "40%",
			"sm-width": "100%"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 12px 0px",
			"color": "--secondary",
			"font": "--headline3",
			"lg-text-align": "center",
			"display": "flex",
			"align-items": "flex-start",
			"text-transform": "uppercase",
			"opacity": "0.8",
			"children": "OUR MISION"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--primary",
			"font": "normal 300 22px/1.5 --fontFamily-sans",
			"lg-text-align": "left",
			"display": "flex",
			"align-items": "flex-start",
			"children": "To craft impactful solutions that drive success, leveraging our team's expertise and passion for embracing cutting-edge technologies and creative methodologies."
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "50%",
			"justify-content": "flex-start",
			"align-items": "flex-start",
			"overflow-x": "hidden",
			"overflow-y": "hidden",
			"lg-width": "100%",
			"lg-align-items": "flex-start",
			"lg-justify-content": "flex-start",
			"margin": "0px 0px 0px 0px",
			"lg-margin": "40px 0px 0px 0px",
			"sm-flex-direction": "column",
			"sm-justify-content": "center"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"width": "100%",
			"overflow-x": "hidden",
			"overflow-y": "hidden",
			"position": "relative",
			"transform": "translateY(0px)",
			"transition": "transform 0.2s ease-in-out 0s",
			"hover-transform": "translateY(-10px)",
			"padding": "0px 0px 20% 0px",
			"border-radius": "24px",
			"lg-width": "auto",
			"lg-margin": "0px 32px 34px 0px",
			"margin": "0px 32px 0px 0px",
			"min-width": "188px",
			"min-height": "188px",
			"sm-margin": "0px 32px 24px 0px"
		}
	},
	"image1": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/abstract-paint-splashing-vibrant-colors-liquid-motion-generated-by-ai.jpg?v=2024-01-02T14:32:17.230Z",
			"object-fit": "cover",
			"position": "absolute",
			"display": "block",
			"width": "100%",
			"top": 0,
			"left": 0,
			"right": 0,
			"bottom": 0,
			"min-height": "100%"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 12px 0px",
			"color": "--secondary",
			"font": "--headline3",
			"lg-text-align": "center",
			"display": "flex",
			"align-items": "flex-start",
			"text-transform": "uppercase",
			"opacity": "0.8",
			"children": "OUR VISION"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--primary",
			"font": "normal 300 22px/1.5 --fontFamily-sans",
			"lg-text-align": "left",
			"display": "flex",
			"align-items": "flex-start",
			"children": "Letacom is built on the foundation of delivering exceptional creativity and strategic prowess to empower businesses."
		}
	}
};

const AboutUs = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
		</Box>
		<Box {...override("box1")}>
			<Box {...override("box2")}>
				<Image {...override("image")} />
			</Box>
			<Box {...override("box3")}>
				<Text {...override("text2")} />
				<Text {...override("text3")} />
			</Box>
		</Box>
		<Box {...override("box4")}>
			<Box {...override("box5")}>
				<Image {...override("image1")} />
			</Box>
			<Box {...override("box6")}>
				<Text {...override("text4")} />
				<Text {...override("text5")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(AboutUs, { ...Section,
	defaultProps,
	overrides
});
export default AboutUs;