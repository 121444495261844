import advertising from "./advertising";
import branding from "./branding";
import design from "./design";
import digital from "./digital";
import logos from "./logo";

//mtf

const projects = { advertising, branding, design, digital, logos };

export const projectBy = (service, project) =>
  projects[service.toLowerCase()]?.find(
    (value, _index, _arr) => value.project === project
  );

export const featuredProjects = [...branding, ...logos];

export default projects;
