import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Section } from "@quarkly/widgets";
import PaymentModelItem from "./PaymentModelItem";
const defaultProps = {
	"padding": "10px 0 60px 0",
	"sm-padding": "16px 0 24px 0",
	"lg-margin": "0 0 0 0",
	"lg-padding": "80px 0 80px 0",
	"quarkly-title": "Price-11"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 600 46px/1.2 --fontFamily-googleMontserrat",
			"text-align": "center",
			"md-margin": "0px 0px 24px 0px",
			"width": "700px",
			"align-self": "center",
			"color": "--primary",
			"sm-margin": "0px 0px 8px 0px",
			"children": "Payment Models",
			"md-width": "100%"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 70px 0px",
			"text-align": "center",
			"font": "normal 300 20px/1.5 --fontFamily-googleMontserrat",
			"width": "700px",
			"align-self": "center",
			"md-width": "100%",
			"color": "--secondary",
			"md-margin": "0px 0px 60px 0px",
			"sm-margin": "0px 0px 24px 0px",
			"children": "How we charge"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			margin: "0px 0px 10px 0px",
			"md-flex-direction": "column",
			"border-width": "1px",
			"lg-flex-direction": "column",
			"border-style": "solid",
			"border-color": "#dadbe2",
			"border-radius": "25px",
			"padding": "10px 10px 10px 0px",
			"lg-padding": "10px 10px 10px 10px"
		}
	},
	"paymentModelItem": {
		"kind": "PaymentModelItem",
		"props": {}
	}
};
const models = [{
	title: "Time & Materials Price",
	description: "Under this contract model, clients pay an agreed-upon wage for every working hour spent on the project. This approach provides transparency, allowing clients to pay for the actual work completed by our team.",
	items: [{
		title: "Advantages",
		items: ["Control over Expenses: Clients have precise control over project expenses, paying for the actual work done.", "Tailored to Evolving Needs: Flexibility to adapt the project as it progresses, accommodating evolving requirements."]
	}, {
		title: "Disadvantages",
		items: ["Ongoing Client Involvement: Continuous client engagement is necessary, potentially requiring active involvement for project changes.", "Ad-hoc Project Changes: Changes during the project might be ad-hoc, requiring quick decisions and adjustments."]
	}]
}, {
	title: "Fixed Price",
	description: "This contract model involves agreeing on a pre-negotiated price for a set number of working hours or specific project deliverables. Clients know the project's cost upfront and agree upon a fixed budget.",
	items: [{
		title: "Advantages",
		items: ["Precise Budget Planning: Clients can plan their budget accurately, knowing the project's cost in advance.", "Defined Scope: Clear understanding of project scope and associated costs right from the start."]
	}, {
		title: "Disadvantages",
		items: ["Lack of Flexibility: Limited room for changes or modifications without additional charges.", "Additional Charges for Changes: Any modifications or extra work beyond the agreed scope may incur additional costs."]
	}]
}, {
	title: "Milestone-Based Payment",
	description: "In this model, payments are tied to specific project milestones or deliverables. Payments are made upon reaching pre-defined project stages.",
	items: [{
		title: "Advantages",
		items: ["Phased Payment Structure: Allows payments to be made based on project progress and achieved milestones.", "Clear Project Progress Tracking: Clients can track project development based on completed milestones."]
	}, {
		title: "Disadvantages",
		items: ["Rigidity in Payment Schedule: May lead to inflexibility in payment timing if milestones are not achieved as planned.", "Potential Misalignment: If milestones are not clearly defined, it could lead to disagreements over payment releases."]
	}]
}, {
	title: "Retainer-Based Model",
	description: "Clients pay a fixed monthly or periodic fee to retain Letacom's services or support for ongoing projects or specific periods.",
	items: [{
		title: "Advantages",
		items: ["Continuous Support: Clients have access to ongoing support and services, fostering a long-term partnership.", "Predictable Costs: Predictable monthly costs for continuous services without fluctuation."]
	}, {
		title: "Disadvantages",
		items: ["Unused Hours: Unused hours or services within the retainer might be seen as wasteful if not fully utilized.", "Scope Limitation: Might limit flexibility or additional work outside the retainer scope without renegotiation."]
	}]
}]; // {
// 		title:"",
// 			description:"",
// 		items:[
// 			{
// 				title:"Advantages",
// 				items:[
// 					"",
// 					""
// 					]
// 			},
// 				{
// 				title:"Disadvantages",
// 				items:[
// 					"",
// 				"",
// 				]
// 			}
// 		]
// },

const PaymentModelsItem = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Text {...override("text")} />
		<Text {...override("text1")} />
		{models.map(e => {
			const item = { ...override("paymentModelItem"),
				...e
			};
			return <Box {...override("box")}>
				 
				<PaymentModelItem {...item} />
				 
			</Box>;
		})}
		{children}
	</Section>;
};

Object.assign(PaymentModelsItem, { ...Section,
	defaultProps,
	overrides
});
export default PaymentModelsItem;