import React from "react";
import { useOverrides } from "@quarkly/components";
import { Hr, Text, Box } from "@quarkly/widgets";
import TitledIconItem from "./TitledIconItem";
import ToLinkbox from "./ToLinkbox";
const defaultProps = {
	"display": "flex",
	"flex-direction": "column",
	"padding": "24px 24px 24px 24px",
	"border-radius": "8px",
	"border-width": "0px 0px 0px 4px",
	"border-style": "solid",
	"border-color": "--color-secondary",
	"box-shadow": "--m",
	"sm-padding": "24px 24px 24px 24px"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--primary",
			"font": "--headline2",
			"sm-font": "normal 700 28px/1.2 \"Source Sans Pro\", sans-serif",
			"children": "Advertising Services"
		}
	},
	"titledIconItem": {
		"kind": "TitledIconItem",
		"props": {}
	},
	"toLinkbox": {
		"kind": "ToLinkbox",
		"props": {
			"href": "/projects",
			"title": "VIEW PROJECTS",
			margin: "24px 0px 0px 0px"
		}
	}
};

const ServiceItem = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const text = override("text");
	text.children = props.title ?? '';
	return <Box {...rest}>
		<Text {...text} />
		{(props.items ?? []).map(e => {
			const item = override("titledIconItem");
			item.title = e;
			return <TitledIconItem {...item} />;
		})}
		<ToLinkbox {...override("toLinkbox")} />
		{children}
	</Box>;
};

Object.assign(ServiceItem, { ...Box,
	defaultProps,
	overrides
});
export default ServiceItem;