import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Image, Section } from "@quarkly/widgets";
import QuarklycommunityKitMarquee from "./QuarklycommunityKitMarquee";
const Clients = [
  "https://i.postimg.cc/bwNr1ryD/0.png",
  "https://i.postimg.cc/3J3xHNDS/1.png",
  "https://i.postimg.cc/QMvMLqp1/2.png",
  "https://i.postimg.cc/J0L404Yh/3.png",
  "https://i.postimg.cc/K8qzfbj9/4.png",
  "https://i.postimg.cc/446xMVnH/5.png",
  "https://i.postimg.cc/Qt5djWbP/6.png",
  "https://i.postimg.cc/x83jzDB3/7.jpg",
  "https://i.postimg.cc/5tXtkrFf/8.png",
];
const defaultProps = {
  padding: "90px 0 120px 0",
  "quarkly-title": "Partners-3",
  background: "--color-light",
};
const overrides = {
  text: {
    kind: "Text",
    props: {
      margin: "0px 0px 20px 0px",
      "text-align": "center",
      font: "normal 500 56px/1.2 --fontFamily-serifGeorgia",
      color: "--secondary",
      "border-color": "--color-secondary",
      "sm-font": "normal 500 48px/1.2 --fontFamily-serifGeorgia",
      children: "Our Clients and partners",
    },
  },
  quarklycommunityKitMarquee: {
    kind: "QuarklycommunityKitMarquee",
    props: {
      pauseOnHover: true,
      width: "100%",
      speed: "15",
      height: "100px",
    },
  },
  image: {
    kind: "Image",
    props: {
      src: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/Invalcre%20Logo%20BW%20Transparent%20NT%20copy.png?v=2024-01-01T12:57:53.821Z",
      display: "inline-block",
      width: "200px",
      margin: "0px 24px 0px 24px",
      height: "100px",
      flex: "0 0 auto",
      "sm-width": "150px",
      "sm-height": "75px",
    },
  },
};

const Partners = (props) => {
  const { override, children, rest } = useOverrides(
    props,
    overrides,
    defaultProps
  );

  return (
    <Section {...rest}>
      <Text {...override("text")} />
      <QuarklycommunityKitMarquee {...override("quarklycommunityKitMarquee")}>
        {Clients.map((e) => (
          <Image {...{ ...override("image"), ...{ src: e } }} />
        ))}
      </QuarklycommunityKitMarquee>
      {children}
    </Section>
  );
};

Object.assign(Partners, { ...Section, defaultProps, overrides });
export default Partners;
