import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Strong, Icon, LinkBox } from "@quarkly/widgets";
import QuarklycommunityKitAnimation from './QuarklycommunityKitAnimation';
import { IoIosArrowRoundForward } from "react-icons/io";
const defaultProps = {
	"flex-direction": "row",
	"align-items": "center",
	"justify-content": "flex-start",
	"color": "--primary",
	"hover-opacity": "0.7",
	"transition": "--opacityOut",
	"width": "fit-content",
	"margin": "24px 0px 024px 0px",
	"href": "/services"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"letter-spacing": "0.3px",
			"font": "normal 500 20px/1.2 --fontFamily-sans",
			"color": "--secondary",
			"children": <Strong
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				user-select="auto"
				pointer-events="auto"
			>
				ALL SERVICES
			</Strong>
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoIosArrowRoundForward,
			"size": "40px",
			"color": "--secondary"
		}
	}
};

const ToLinkbox = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const box = { ...rest
	};
	box.href = props.href ?? "/services";
	const text = override("text");
	text.children = props.title ?? "ALL SERVICES";
	return <LinkBox {...box}>
		<Text {...text} />
		<QuarklycommunityKitAnimation trigger="hover" animation="→ Slide In">
			<Icon {...override("icon")} />
		</QuarklycommunityKitAnimation>
		{children}
	</LinkBox>;
};

Object.assign(ToLinkbox, { ...LinkBox,
	defaultProps,
	overrides
});
export default ToLinkbox;