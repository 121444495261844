import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box } from "@quarkly/widgets";
const defaultProps = {
	"min-width": "10px",
	"min-height": "10px",
	"display": "flex",
	"padding": "25px 0px 0px 0px",
	"border-width": "1px 0 0 0",
	"border-style": "solid",
	"border-color": "--color-secondaryL1",
	"md-flex-direction": "column"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 25px/1.2 --fontFamily-sans",
			"color": "--primary",
			"width": "40%",
			"md-width": "100%"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 18px/1.5 --fontFamily-sansTrebuchet",
			"color": "#555a5f",
			"width": "60%",
			"padding": "0px 0px 0px 50px",
			"md-width": "100%",
			"md-padding": "0px 0px 0px 0"
		}
	}
};

const FaqItem = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const qxn = override("text");
	const ans = override("text1");
	qxn.children = props.question;
	ans.children = props.answer;
	return <Box {...rest}>
		<Text {...qxn} />
		<Text {...ans} />
		{children}
	</Box>;
};

Object.assign(FaqItem, { ...Box,
	defaultProps,
	overrides
});
export default FaqItem;