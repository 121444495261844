import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Section } from "@quarkly/widgets";
import MembershipItem from "./MembershipItem";
const defaultProps = {
	"padding": "80px 0 80px 0",
	"background": "--color-secondaryL1"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"align-items": "center",
			"flex-direction": "column",
			"justify-content": "center",
			"margin": "0px 0px 64px 0px",
			"width": "100%",
			"sm-margin": "0px 0px 30px 0px",
			"padding": "0px 200px 0px 200px",
			"lg-padding": "0px 0px 0px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 16px 0px",
			"font": "--headline1",
			"text-align": "center",
			"sm-font": "normal 700 42px/1.2 \"Source Sans Pro\", sans-serif",
			"color": "--primary",
			"children": "MEMBERSHIPS AND AFFILIATIONS"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--primary",
			"text-align": "center",
			"font": "--lead",
			"children": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "grid",
			"flex-wrap": "wrap",
			"width": "100%",
			"align-items": "center",
			"justify-content": "center",
			"grid-template-columns": "repeat(2, 1fr)",
			"grid-gap": "30px",
			"md-grid-template-columns": "1fr"
		}
	},
	"membershipItem": {
		"kind": "MembershipItem",
		"props": {}
	}
};
const items = [{
	src: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/zim-gd.jpg?v=2024-01-03T08:56:20.166Z",
	title: "National Association of Graphic Designers (NAGD)",
	details: "Letacom holds an active membership with the National Association of Graphic Designers (NAGD), a prestigious organization dedicated to promoting excellence in graphic design. Being a member of NAGD reflects Letacom's commitment to adhering to industry standards and staying updated with the latest trends and practices in graphic design."
}, {
	src: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/NEW%20LOGO%20WGD%202019%20web%202.webp?v=2024-01-03T08:57:33.552Z",
	title: "Global Graphic Designers (GGD)",
	details: "Letacom is a proud member of Global Graphic Designers (GGD), an international community fostering collaboration and innovation in the field of graphic design. Membership in GGD signifies Letacom's global outlook and engagement with diverse design perspectives, contributing to its creative expertise."
}, {
	src: "https://images.unsplash.com/photo-1497215842964-222b430dc094?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000",
	title: "Entrepreneurs Desk",
	details: "Letacom is affiliated with Entrepreneurs Desk, a vibrant platform supporting entrepreneurial endeavors and fostering business growth. Through this affiliation, Letacom accesses valuable resources, networking opportunities, and knowledge-sharing sessions that contribute to its entrepreneurial development."
}, {
	src: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/smedco_logo.jpeg?v=2024-01-03T08:58:35.486Z",
	title: "SMEDCO - Small & Medium Enterprises Development Corporation",
	details: "Letacom is associated with SMEDCO, a prominent organization dedicated to supporting and empowering small and medium enterprises. Letacom's membership in SMEDCO reflects its commitment to the growth and development of small businesses and contributes to its engagement in the SME ecosystem."
}]; // {
// 	src:"",
// 	title:"",
// 	details:""
// },

const MembershipsList = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
		</Box>
		<Box {...override("box1")}>
			{items.map(e => {
				const item = { ...override("membershipItem"),
					...e
				};
				return <MembershipItem {...item} />;
			})}
		</Box>
		{children}
	</Section>;
};

Object.assign(MembershipsList, { ...Section,
	defaultProps,
	overrides
});
export default MembershipsList;