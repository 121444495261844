import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Hr, Box, Icon } from "@quarkly/widgets";
import { IoIosCheckmark } from "react-icons/io";
const defaultProps = {
	"min-width": "100px",
	"min-height": "100px",
	"padding": "30px 80px 30px 30px",
	"border-color": "#dadbe2",
	"display": "flex",
	"flex-direction": "column",
	"position": "relative",
	"z-index": "2",
	"lg-width": "100%",
	"lg-padding": "30px 30px 30px 30px",
	"font": "normal 300 20px/1.5 --fontFamily-googleMontserrat",
	"md-border-width": "1px",
	"md-align-items": "stretch",
	"margin": "0 0px 0px 0px",
	"lg-margin": "0 0px 0 0px",
	"lg-border-radius": "25px",
	"justify-content": "center",
	"sm-padding": "10px 10px 10px 10px"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"margin": "0px 0px 35px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 600 25px/1.5 --fontFamily-googleMontserrat",
			"color": "--primary",
			"lg-margin": "0px 0px 20px 0px",
			"sm-margin": "0px 0px 10px 0px"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 300 17px/1.5 --fontFamily-googleMontserrat",
			"color": "#80868a",
			"children": "Our Pro Plan is designed for growing businesses that need more advanced features and support. Contact our sales team to learn more about pricing and how we can support your business."
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": 0,
			"min-height": 0,
			"display": "flex",
			margin: "15px 0px 15px 0px",
			"grid-template-columns": "repeat(2, 1fr)",
			"align-items": "center",
			"justify-content": "space-between",
			"sm-flex-direction": "column",
			"sm-align-items": "flex-start"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"font": "normal 600 18px/1.5 --fontFamily-googleMontserrat",
			"color": "--secondary",
			"lg-margin": "0px 0px 20px 0px",
			"margin": "0 5px 0 0px",
			"children": "Variants: Monthly, Annual"
		}
	},
	"hr": {
		"kind": "Hr",
		"props": {
			"min-height": "1px",
			"min-width": "100px",
			"margin": "0px 0px 0px 0px",
			"display": "flex",
			"width": "70%",
			"sm-width": "100%",
			"border-color": "--color-secondaryL1"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "grid",
			"grid-template-columns": "repeat(2, 1fr)",
			"sm-grid-template-columns": "1fr"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"align-items": "center",
			"sm-margin": "0px 0px 10px 0px"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoIosCheckmark,
			"size": "32px",
			"color": "--secondary",
			"margin": "0px 8px 0px 0px",
			"align-self": "flex-start"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 17px/1.2 --fontFamily-googleMontserrat",
			"color": "#80868a",
			"children": "including advanced analytics"
		}
	}
};

const PaymentModelItem = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const title = override("text");
	const description = override("text1");
	description.children = props.description;
	title.children = props.title;
	const items = props.items ?? [];
	return <Box {...rest}>
		<Box {...override("box")}>
			<Text {...title} />
			<Text {...description} />
			{items.map(e => {
				const title = override("text2");
				title.children = e.title;
				return <Box>
					<Box {...override("box1")}>
						<Text {...title} />
						<Hr {...override("hr")} />
					</Box>
					<Box {...override("box2")}>
						{e.items.map(e1 => {
							const item = override("text3");
							item.children = e1;
							return <Box {...override("box3")}>
								<Icon {...override("icon")} />
								<Text {...item} />
							</Box>;
						})}
					</Box>
				</Box>;
			})}
		</Box>
		{children}
	</Box>;
};

Object.assign(PaymentModelItem, { ...Box,
	defaultProps,
	overrides
});
export default PaymentModelItem;