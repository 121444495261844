import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Structure } from "@quarkly/widgets";
import ProjectItem from "./ProjectItem";
import { featuredProjects } from "data/projects";
const defaultProps = {
  "cells-number-total": "3",
  "cells-number-group": "3",
};
const overrides = {
  overrideOverride: {
    kind: "Override",
    props: {
      slot: "cell-0",
    },
  },
  projectItem: {
    kind: "ProjectItem",
    props: {},
  },
};

const FeaturedProjects = (props) => {
  const { override, children, rest } = useOverrides(
    props,
    overrides,
    defaultProps
  );

  return (
    <Structure
      {...{ ...rest, ...{ "cells-number-total": featuredProjects.length } }}
    >
      <Override
        slot="Content"
        grid-template-columns="repeat(3, 4fr)"
        sm-grid-template-columns="12fr"
        md-grid-template-columns="repeat(2, 1fr)"
      >
        {featuredProjects.map((e) => {
          return (
            <Override {...{ slot: `cell-${featuredProjects.indexOf(e)}` }}>
              <ProjectItem {...{ ...override("projectItem"), ...e }} />
            </Override>
          );
        })}
      </Override>
      {children}
    </Structure>
  );
};

Object.assign(FeaturedProjects, { ...Structure, defaultProps, overrides });
export default FeaturedProjects;
