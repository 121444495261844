import React from "react";
import { useOverrides } from "@quarkly/components";
import { Image, LinkBox, Text, Link, Span, Box, Section } from "@quarkly/widgets";
import QuarklycommunityKitAnimation from "./QuarklycommunityKitAnimation";
import FooterServices from "./FooterServices";
import SocialMediaLinks from "./SocialMediaLinks";
const defaultProps = {
	"padding": "80px 0 80px 0",
	"background": "--color-primary",
	"quarkly-title": "Footer-7",
	"sm-padding": "40px 0 40px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"lg-width": "100%",
			"flex-direction": "row",
			"lg-flex-direction": "column",
			"justify-content": "space-between",
			"width": "100%",
			"padding": "0 0px 50px 0px",
			"md-padding": "0 0px 30px 0px",
			"lg-padding": "0 0px 40px 0px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"padding": "0 0px 0 50px",
			"display": "flex",
			"grid-template-columns": "repeat(2, 1fr)",
			"border-color": "--color-lightD2",
			"md-grid-template-columns": "1fr",
			"md-padding": "20px 0px 20px 0px",
			"flex-direction": "column",
			"lg-padding": "0 0px 0 0",
			"height": "100%"
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"height": "50px",
			"width": "100px"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/royal%20&%20white.png?v=2023-12-30T13:39:59.246Z",
			"display": "block",
			"height": "60px",
			"width": "100%",
			"object-fit": "cover"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"padding": "0px 50px 0px 0px",
			"md-padding": "0px 0 0px 0px",
			"md-margin": "0px 0px 25px 0px",
			"margin": "0px 0px 35px 0px",
			"lg-margin": "0px 0px 25px 0px",
			"height": "100%",
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 10px 0px",
			"font": "normal 500 28px/1.2 --fontFamily-sans",
			"sm-text-align": "left",
			"color": "--light",
			"children": <>
				Lets Discuss Ideas,
				<br />
				Just Say Hello
				<br />
				<Span
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<Link
						href="mailto:hello@letacom.com?Hello Letacom"
						color="--secondary"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
						text-decoration-line="initial"
						transition="all 0.5s ease-in-out 0s"
						transform="translateY(10px)"
						hover-transform="translateY(0px)"
						font="normal 500 22px/1.2 --fontFamily-sans"
					>
						hello@letacom.com
					</Link>
				</Span>
			</>
		}
	},
	"quarklycommunityKitAnimation": {
		"kind": "QuarklycommunityKitAnimation",
		"props": {
			"trigger": "hover",
			"animation": "Pop",
			"duration": "2s",
			"min-width": "25%",
			"min-height": "60px",
			"margin": "16px 0px 16px 0px",
			"flex": "0 1 auto",
			"display": "flex",
			"align-items": "center",
			"iteration": "infinite",
			"test": false
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "/contactus",
			"color": "--light",
			"padding": "8px 16px 8px 16px",
			"text-decoration-line": "initial",
			"border-radius": "24px",
			"text-align": "center",
			"font": "normal 500 20px/1.2 --fontFamily-googleMontserrat",
			"width": "100%",
			"min-width": "200px",
			"display": "flex",
			"align-items": "center",
			"justify-content": "center",
			"border-style": "solid",
			"border-color": "--color-secondaryL1",
			"border-width": "2px",
			"children": "CONTACT US"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"lg-width": "70%",
			"width": "70%",
			"display": "flex",
			"lg-align-items": "flex-start",
			"lg-margin": "0px 0px 35px 0px",
			"md-margin": "0px 0px 25px 0px",
			"md-width": "100%",
			"sm-justify-content": "flex-start",
			"flex": "0 1 auto"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"grid-template-columns": "repeat(2, 1fr)",
			"grid-gap": "16px 24px",
			"lg-align-items": "start",
			"md-grid-gap": "36px 34px",
			"sm-grid-template-columns": "repeat(2, 1fr)",
			"padding": "0px 0 0px 0px",
			"sm-width": "100%",
			"sm-grid-gap": "36px 34px",
			"align-self": "flex-end",
			"justify-items": "center",
			"sm-justify-items": "start",
			"md-justify-items": "start",
			"lg-justify-items": "start"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"align-items": "flex-start",
			"margin": "0px 0px 0px 10px",
			"lg-justify-content": "start",
			"lg-align-items": "flex-start",
			"justify-content": "start",
			"display": "grid",
			"lg-flex-direction": "column",
			"lg-margin": "0px 0px 0px 0px",
			"flex-direction": "column",
			"flex-wrap": "wrap",
			"align-content": "start",
			"grid-gap": "8px 0",
			"md-justify-content": "start",
			"min-width": "25%"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 500 20px/1.5 --fontFamily-sans",
			"color": "--light",
			"children": "Company"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"border-color": "--color-primary",
			"display": "flex",
			"font": "normal 400 18px/1.5 --fontFamily-googleMontserrat",
			"margin": "0px 0 0px 0",
			"lg-border-width": "0px 0px 0px 2px",
			"href": "/about-us",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "#a78bfa",
			"children": "About Us"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"display": "flex",
			"href": "/projects",
			"font": "normal 400 18px/1.5 --fontFamily-googleMontserrat",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "#a78bfa",
			"children": "Our Projects"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "#a78bfa",
			"href": "/faqs",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"font": "normal 400 18px/1.5 --fontFamily-googleMontserrat",
			"display": "flex",
			"children": "FAQs"
		}
	},
	"link4": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "#a78bfa",
			"href": "/careers",
			"font": "normal 400 18px/1.5 --fontFamily-googleMontserrat",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"display": "flex",
			"children": "Careers"
		}
	},
	"link5": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "#a78bfa",
			"href": "/how-we-work",
			"font": "normal 400 18px/1.5 --fontFamily-googleMontserrat",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"display": "flex",
			"children": "How we work?"
		}
	},
	"link6": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "#a78bfa",
			"href": "/our-tech-stack",
			"font": "normal 400 18px/1.5 --fontFamily-googleMontserrat",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"display": "flex",
			"children": "Our tech stack"
		}
	},
	"footerServices": {
		"kind": "FooterServices",
		"props": {}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"justify-content": "space-between",
			"padding": "50px 0px 0px 0px",
			"border-width": "1px 0 0 0",
			"border-style": "solid",
			"border-color": "--color-lightD1",
			"md-padding": "30px 0px 0px 0px",
			"md-flex-direction": "column",
			"lg-padding": "40px 0px 0px 0px"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "#c3c8d0",
			"md-margin": "0px 0px 25px 0px",
			"sm-text-align": "left",
			"children": "© 2023 Letacom (pvt) Limited. All rights reserved."
		}
	},
	"socialMediaLinks": {
		"kind": "SocialMediaLinks",
		"props": {}
	},
	"link7": {
		"kind": "Link",
		"props": {
			"color": "--lightD2",
			"text-decoration-line": "initial",
			"font": "--base",
			"href": "https://wa.link/65if0k",
			"target": "_blank",
			"sm-margin": "24px 0px 0px 0px",
			"md-margin": "24px 0px 0px 0px",
			"children": "Powered by Invalcre (pvt) Limited"
		}
	}
};

const LetacomFooter = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<LinkBox {...override("linkBox")}>
					<Image {...override("image")} />
				</LinkBox>
				<Box {...override("box2")}>
					<Text {...override("text")} />
					<QuarklycommunityKitAnimation {...override("quarklycommunityKitAnimation")}>
						<Link {...override("link")} />
					</QuarklycommunityKitAnimation>
				</Box>
			</Box>
			<Box {...override("box3")}>
				<Box {...override("box4")}>
					<Box {...override("box5")}>
						<Text {...override("text1")} />
						<Link {...override("link1")} />
						<Link {...override("link2")} />
						<Link {...override("link3")} />
						<Link {...override("link4")} />
						<Link {...override("link5")} />
						<Link {...override("link6")} />
					</Box>
					<FooterServices {...override("footerServices")} />
				</Box>
			</Box>
		</Box>
		<Box {...override("box6")}>
			<Text {...override("text2")} />
			<SocialMediaLinks {...override("socialMediaLinks")} />
			<Link {...override("link7")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(LetacomFooter, { ...Section,
	defaultProps,
	overrides
});
export default LetacomFooter;