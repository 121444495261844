import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Section } from "@quarkly/widgets";
import QualificationItem from "./QualificationItem";
const defaultProps = {
	"padding": "60px 0 90px 0",
	"sm-padding": "40px 0",
	"min-height": "500px",
	"sm-min-height": "auto",
	"background": "--color-light",
	"display": "flex",
	"lg-padding": "45px 0 45px 0",
	"quarkly-title": "Advantages/Features-26"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "100%",
			"flex-direction": "column",
			"align-items": "center",
			"lg-width": "100%",
			"sm-margin": "0px 0px 40px 0px",
			"sm-padding": "0px 0px 0px 0px",
			"lg-flex-direction": "row",
			"lg-flex-wrap": "wrap",
			"lg-justify-content": "center"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"margin": "0px 0px 0px 0px",
			"font": "normal 600 40px/1.5 --fontFamily-googleMontserrat",
			"color": "--primary",
			"width": "100%",
			"md-width": "100%",
			"sm-font": "normal 600 40px/1.2 --fontFamily-googleMontserrat",
			"text-align": "center",
			"sm-text-align": "left",
			"children": "Qualifications"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "8px 0px 0px 0px",
			"font": "--base",
			"text-align": "center",
			"color": "--primary",
			"lg-max-width": "720px",
			"sm-text-align": "left",
			"children": "Learn how we ae ceivering personalization st scal scross sectors"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "grid",
			"width": "100%",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "24px",
			"margin": "36px 0px 0px 0px",
			"max-width": "1000px",
			"md-grid-template-columns": "repeat(2, 1fr)",
			"sm-grid-template-columns": "1fr"
		}
	},
	"qualificationItem": {
		"kind": "QualificationItem",
		"props": {}
	}
};
const items = [{
	title: "Graphic Design",
	description: "Proficient in visual communication and design principles, transforming ideas into compelling visual content."
}, {
	title: "Web Design",
	description: "Skilled in crafting engaging and user-centric web interfaces, ensuring seamless user experiences."
}, {
	title: "Digital Technology",
	description: "Experienced in leveraging digital tools and technologies to innovate and drive efficiency."
}, {
	title: "Fine Art",
	description: "Bringing artistic excellence and creativity to our projects, enhancing visual aesthetics."
}, {
	title: "Advertising",
	description: "Expertise in developing and executing impactful advertising strategies to enhance brand visibility."
}, {
	title: "Multimedia Design",
	description: "Proficient in creating diverse multimedia content to engage and inform audiences."
}, {
	title: "UI/UX Design",
	description: "Focused on enhancing user experience and interface design, ensuring intuitive interactions."
},, {
	title: "Software Engineering",
	description: "Adept at developing robust and efficient software solutions, driving technological advancements."
}, {
	title: "Computer Science",
	description: "Knowledgeable in computer science principles and innovative problem-solving."
},, {
	title: "Information Technology",
	description: "Experts in leveraging technology to optimize operations and drive business growth."
}, {
	title: "Marketing",
	description: "Proficient in understanding market trends and developing effective marketing strategies."
},, {
	title: "Front End Development",
	description: "Skilled in crafting engaging user interfaces and experiences on the front end."
}, {
	title: "Back End Development",
	description: "Experienced in building and maintaining efficient server-side applications."
}, {
	title: "Game Developers",
	description: "Creative professionals experienced in designing and developing engaging games for our digital content solutions."
},, {
	title: "Network Engineering",
	description: "Skilled individuals proficient in designing, implementing, and maintaining network infrastructures."
}, {
	title: "Business Development",
	description: "Professionals experienced in driving business growth through strategic initiatives and partnerships."
}, {
	title: "Accounting",
	description: "Individuals with expertise in financial management and accounting principles."
}]; //  {
//     title:"",
//     description:"",
//   },

const Qualifications = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			flex-direction="row"
			flex-wrap="wrap"
			grid-gap="5%"
			max-width="1200px"
			sm-min-width="280px"
		/>
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Box {...override("box1")}>
				{items.map(e => {
					const item = { ...override("qualificationItem"),
						...e
					};
					return <QualificationItem {...item} />;
				})}
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Qualifications, { ...Section,
	defaultProps,
	overrides
});
export default Qualifications;