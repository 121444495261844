export default [
  {
    value: "advertising",
    label: "Advertising",
  },
  {
    value: "branding",
    label: "Branding",
  },
  {
    value: "digital",
    label: "Digital",
  },
  {
    value: "design",
    label: "Design",
  },
  {
    value: "logos",
    label: "logos",
  },
];
