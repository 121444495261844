import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Section } from "@quarkly/widgets";
import { projectBy } from "data/projects";
const defaultProps = {
  padding: "40px 0 40px 0",
  "sm-padding": "60px 0 80px 0",
  "quarkly-title": "Content-9",
};
const overrides = {
  box: {
    kind: "Box",
    props: {
      "min-width": "100px",
      "min-height": "100px",
      width: "100%",
    },
  },
  text: {
    kind: "Text",
    props: {
      margin: "0px 0px 30px 0px",
      font: "normal 600 42px/1.2  --fontFamily-googleMontserrat",
      "md-font": "normal 600 28px/1.2  --fontFamily-googleMontserrat",
      "sm-font": "normal 600 22px/1.2  --fontFamily-googleMontserrat",
      color: "--primary",
      children: "Invalcre Brand Identity",
    },
  },
  text1: {
    kind: "Text",
    props: {
      margin: "0px 0px 35px 0px",
      font: "normal 300 18px/1.5 --fontFamily-googleMontserrat",
      "sm-font": "normal 300 16px/1.5 --fontFamily-googleMontserrat",
      color: "--primary",
      width: "100%",
      children: (
        <>
          Invalcre (Pvt) Limited, a pioneering force in technology-driven value
          creation, partnered with Letacom to redefine its brand identity.
          Together, leveraging Letacom's expertise in innovative solutions, the
          collaboration focused on crafting a dynamic brand image. Letacom's
          tailored approach and deep understanding of technology seamlessly
          translated Invalcre's vision into a vibrant visual identity,
          encompassing logos and associated elements. <br />
          <br />
          The result? A brand identity that authentically reflects Invalcre's
          commitment to technological advancement, innovation, and value
          creation, positioning them at the forefront of their industry.
        </>
      ),
    },
  },
  box1: {
    kind: "Box",
    props: {
      "min-width": "100px",
      "min-height": "100px",
      display: "grid",
      "grid-template-columns": "repeat(3, 2fr)",
      "grid-gap": "16px 16px",
      padding: "15px 0px 0px 0px",
      "border-width": "1px 0 0 0",
      "border-style": "solid",
      "border-color": "--color-secondaryL1",
      margin: "0px 0px 30px 0px",
      "sm-grid-template-columns": "repeat(2, 1fr)",
      width: "80%",
    },
  },
  box2: {
    kind: "Box",
    props: {
      "min-width": "100px",
      "min-height": "100px",
    },
  },
  text2: {
    kind: "Text",
    props: {
      margin: "0px 0px 15px 0px",
      font: "normal 400 16px/1.5 --fontFamily-googleMontserrat",
      color: "--primary",
      children: "BRAND",
    },
  },
  text3: {
    kind: "Text",
    props: {
      margin: "0px 0px 0px 0px",
      font: "normal 600 20px/1.2 --fontFamily-googleMontserrat",
      color: "--primary",
      children: "Invalcre (Pvt) Limited",
    },
  },
  box3: {
    kind: "Box",
    props: {
      "min-width": "100px",
      "min-height": "100px",
    },
  },
  text4: {
    kind: "Text",
    props: {
      margin: "0px 0px 15px 0px",
      font: "normal 400 16px/1.5 --fontFamily-googleMontserrat",
      color: "--primary",
      "text-transform": "uppercase",
      children: "SERVICE",
    },
  },
  text5: {
    kind: "Text",
    props: {
      margin: "0px 0px 0px 0px",
      font: "normal 600 20px/1.2 --fontFamily-googleMontserrat",
      color: "--primary",
      children: "Design Services",
    },
  },
  box4: {
    kind: "Box",
    props: {
      "min-width": "100px",
      "min-height": "100px",
    },
  },
  text6: {
    kind: "Text",
    props: {
      margin: "0px 0px 15px 0px",
      font: "normal 400 16px/1.5 --fontFamily-googleMontserrat",
      color: "--primary",
      children: "YEAR",
    },
  },
  text7: {
    kind: "Text",
    props: {
      margin: "0px 0px 0px 0px",
      font: "normal 600 20px/1.2 --fontFamily-googleMontserrat",
      color: "--primary",
      children: "2022",
    },
  },
};

const ProjectDescription = (props) => {
  const { override, children, rest } = useOverrides(
    props,
    overrides,
    defaultProps
  );

  const project = projectBy(props.service, props.project) ?? {};

  return (
    <Section {...rest}>
      <Override
        slot="SectionContent"
        padding="0px 0 0px 0px"
        lg-padding="0px 0 0px 0px"
        display="flex"
        grid-template-columns="repeat(2, 1fr)"
        align-items="start"
        lg-grid-template-columns="1fr"
      />
      <Box {...override("box")}>
        <Text {...{ ...override("text"), ...{ children: project.title } }} />
        <Text
          {...{ ...override("text1"), ...{ children: project.description } }}
        />
        <Box {...override("box1")}>
          <Box {...override("box2")}>
            <Text {...override("text2")} />
            <Text
              {...{ ...override("text3"), ...{ children: project.brand } }}
            />
          </Box>
          <Box {...override("box3")}>
            <Text {...override("text4")} />
            <Text
              {...{ ...override("text5"), ...{ children: project.service } }}
            />
          </Box>
          <Box {...override("box4")}>
            <Text {...override("text6")} />
            <Text
              {...{ ...override("text7"), ...{ children: project.year } }}
            />
          </Box>
        </Box>
      </Box>
      {children}
    </Section>
  );
};

Object.assign(ProjectDescription, { ...Section, defaultProps, overrides });
export default ProjectDescription;
