import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import {
  Text,
  Span,
  Box,
  Input,
  Button,
  Link,
  Section,
  LinkBox,
  Icon,
} from "@quarkly/widgets";
import QuarklycommunityKitLottie from "./QuarklycommunityKitLottie";
import FormLabel from "./FormLabel";
import LetaSelect from "./LetaSelect";
import QuarklycommunityKitNetlifyForm from "./QuarklycommunityKitNetlifyForm";
import { FaWhatsapp } from "react-icons/fa";
const defaultProps = {
  padding: "80px 0 80px 0",
  position: "relative",
  "quarkly-title": "Form-4",
};
const overrides = {
  linkBox: {
    kind: "LinkBox",
    props: {
      "flex-direction": "row",
      "border-width": "1px",
      "border-color": "--color-green",
      "border-radius": "8px",
      "border-style": "solid",
      target: "_blank",
      href: "https://wa.me/message/EFYVDRGZC4HEP1",
      padding: "2px 16px 2px 16px",
      margin: "0px 16px 0px 16px",
    },
  },
  box: {
    kind: "Box",
    props: {
      "z-index": "1",
      left: "0px",
      top: "0px",
      right: "auto",
      bottom: "auto",
      position: "static",
      "grid-template-columns": "repeat(2, 1fr)",
    },
  },
  box1: {
    kind: "Box",
    props: {
      "min-width": "100px",
      "min-height": "100px",
    },
  },
  box2: {
    kind: "Box",
    props: {
      "min-width": "100px",
      "min-height": "100px",
      display: "flex",
      "flex-direction": "row",
      "align-items": "flex-start",
    },
  },
  text: {
    kind: "Text",
    props: {
      "lg-padding": "0px 0px 0px 0px",
      "sm-font": "normal 700 28px/1.2 --fontFamily-sans",
      margin: "0px 0px 18px 0px",
      font: "normal 900 36px/1.2 --fontFamily-googleMontserrat",
      color: "--primary",
      "text-align": "left",
      padding: "0px 0 0px 0px",
      "lg-width": "100%",
      "lg-margin": "0px 0px 25px 0px",
      "sm-margin": "0px 0px 8px 0px",
      children: (
        <>
          <Span
            color="--secondary"
            overflow-wrap="normal"
            word-break="normal"
            white-space="normal"
            text-indent="0"
            text-overflow="clip"
            hyphens="manual"
            user-select="auto"
            pointer-events="auto"
          >
            Have an idea?{" "}
          </Span>
          <br />
          Share with us, and let's bring it to life!
        </>
      ),
    },
  },
  quarklycommunityKitLottie: {
    kind: "QuarklycommunityKitLottie",
    props: {
      path: "https://lottie.host/19690759-0901-4fe1-b8b0-1326d0b91f23/6sZJVHoc99.json",
      display: "flex",
      position: "static",
      width: "45%",
      height: "150px",
      "sm-height": "100px",
    },
  },
  box3: {
    kind: "Box",
    props: {
      display: "flex",
      "lg-flex-direction": "column",
    },
  },
  box4: {
    kind: "Box",
    props: {
      position: "relative",
      "z-index": "1",
      display: "flex",
      "lg-flex-direction": "column",
      "flex-direction": "column",
      "align-items": "center",
      margin: "24px 0px 0px 0px",
      width: "70%",
      "md-width": "100%",
    },
  },
  quarklycommunityKitNetlifyForm: {
    kind: "QuarklycommunityKitNetlifyForm",
    props: {
      "lg-margin": "0px 0px 35px 0px",
      "sm-margin": "0px 0px 25px 0px",
      "sm-width": "100%",
      formName: "contact-us",
    },
  },
  box5: {
    kind: "Box",
    props: {
      display: "flex",
      "lg-flex-wrap": "wrap",
      margin: "0px 0px 15px 0px",
      "flex-direction": "column",
    },
  },
  box6: {
    kind: "Box",
    props: {
      "flex-wrap": "wrap",
      display: "flex",
    },
  },
  box7: {
    kind: "Box",
    props: {
      width: "50%",
      display: "flex",
      padding: "16px 16px 16px 16px",
      "flex-direction": "column",
      "sm-width": "100%",
    },
  },
  formLabel: {
    kind: "FormLabel",
    props: {
      required: true,
      label: "Name",
    },
  },
  input: {
    kind: "Input",
    props: {
      "border-color": "--primary",
      "border-width": "0 0 1px 0",
      "border-radius": "0px",
      required: true,
      padding: "14px 16px 14px 16px",
      name: "Name",
      type: "text",
      background: "rgba(0, 0, 0, 0)",
      placeholder: "Peter",
    },
  },
  box8: {
    kind: "Box",
    props: {
      "flex-direction": "column",
      "sm-width": "100%",
      width: "50%",
      display: "flex",
      padding: "16px 16px 16px 16px",
    },
  },
  formLabel1: {
    kind: "FormLabel",
    props: {
      required: true,
      label: "Email address",
    },
  },
  input1: {
    kind: "Input",
    props: {
      padding: "14px 16px 14px 16px",
      placeholder: "ptwn@mailbox.com",
      name: "mail",
      type: "email",
      "border-color": "--primary",
      "border-width": "0 0 1px 0",
      "border-radius": "0px",
      required: true,
      background: "rgba(0, 0, 0, 0)",
    },
  },
  box9: {
    kind: "Box",
    props: {
      width: "50%",
      "sm-width": "100%",
      display: "flex",
      padding: "16px 16px 16px 16px",
      "flex-direction": "column",
    },
  },
  formLabel2: {
    kind: "FormLabel",
    props: {
      label: "Phone number",
    },
  },
  input2: {
    kind: "Input",
    props: {
      name: "tel",
      type: "tel",
      "border-color": "--primary",
      "border-width": "0 0 1px 0",
      "border-radius": "0px",
      padding: "14px 16px 14px 16px",
      placeholder: "+27 63 974-6731",
    },
  },
  box10: {
    kind: "Box",
    props: {
      width: "50%",
      "sm-width": "100%",
      display: "flex",
      padding: "16px 16px 16px 16px",
      "flex-direction": "column",
    },
  },
  formLabel3: {
    kind: "FormLabel",
    props: {
      label: "Preferred Contact Time",
    },
  },
  input3: {
    kind: "Input",
    props: {
      name: "time",
      type: "number",
      "border-color": "--primary",
      "border-width": "0 0 1px 0",
      "border-radius": "0px",
      padding: "14px 16px 14px 16px",
      placeholder: "10:00-16:00",
    },
  },
  box11: {
    kind: "Box",
    props: {
      width: "50%",
      "sm-width": "100%",
      display: "flex",
      padding: "16px 16px 16px 16px",
      "flex-direction": "column",
    },
  },
  formLabel4: {
    kind: "FormLabel",
    props: {
      label: "Interested Service(s)",
    },
  },
  letaSelect: {
    kind: "LetaSelect",
    props: {
      name: "services",
      isMulti: true,
      placeholder: "Advertising Services",
      options: [
        {
          value: "advertising",
          label: "Advertising Services",
        },
        {
          value: "digital",
          label: "Digital Services",
        },
        {
          value: "design",
          label: "Design Services",
        },
        {
          value: "interactive",
          label: "Interactive Services",
        },
        {
          value: "mobileDigital",
          label: "Mobile Digital Content Solutions",
        },
        {
          value: "crm",
          label: "Creative Project Management Services",
        },
        {
          value: "sss",
          label: "Software Solutions Services",
        },
        {
          value: "other",
          label: "Other (please specify in the description field)",
        },
      ],
    },
  },
  box12: {
    kind: "Box",
    props: {
      width: "50%",
      "sm-width": "100%",
      display: "flex",
      padding: "16px 16px 16px 16px",
      "flex-direction": "column",
    },
  },
  formLabel5: {
    kind: "FormLabel",
    props: {
      label: "Approximate budget",
    },
  },
  letaSelect1: {
    kind: "LetaSelect",
    props: {
      name: "budget",
      placeholder: "Less than $1,000",
      options: [
        {
          value: "<$1000",
          label: "Less than $1,000",
        },
        {
          value: "$1,000 - $5,000",
          label: "$1,000 - $5,000",
        },
        {
          value: "$5,000 - $10,000",
          label: "$5,000 - $10,000",
        },
        {
          value: "$10,000 - $50,000",
          label: "$10,000 - $50,000",
        },
        {
          value: "50,000+",
          label: "Above $50,000",
        },
      ],
    },
  },
  box13: {
    kind: "Box",
    props: {
      padding: "16px 16px 16px 16px",
      width: "100%",
      display: "flex",
      "flex-direction": "column",
      "align-items": "flex-start",
      "sm-width": "100%",
    },
  },
  formLabel6: {
    kind: "FormLabel",
    props: {
      required: true,
      label: "Project Details",
    },
  },
  input4: {
    kind: "Input",
    props: {
      type: "text",
      width: "100%",
      placeholder: "Describe your project or idea",
      name: "text",
      "border-color": "--primary",
      "border-width": "0 0 1px 0",
      "border-radius": "0px",
      as: "textarea",
      "min-height": "150px",
      padding: "14px 16px 14px 16px",
      background: "rgba(0, 0, 0, 0)",
    },
  },
  text1: {
    kind: "Text",
    props: {
      margin: "8px 0px 32px 0px",
      font: "--base",
      color: "--secondary",
      children: "5000 characters left",
    },
  },
  button: {
    kind: "Button",
    props: {
      padding: "12px 68px 12px 68px",
      font: "--link",
      background: "--color-primary",
      "border-radius": "10px",
      "hover-opacity": ".8",
      "min-width": "50%",
      font: "normal 500 20px/1.2 --fontFamily-googleMontserrat",
    },
  },
  box14: {
    kind: "Box",
    props: {
      "align-self": "center",
      margin: "0px 0px 0px 16px",
      "md-width": "100%",
      "lg-display": "flex",
      "sm-flex-direction": "column",
    },
  },
  box15: {
    kind: "Box",
    props: {
      "min-width": "100px",
      "min-height": "100px",
      margin: "8px 0 24px 0px",
      "lg-width": "30%",
      "md-width": "45%",
      "sm-width": "100%",
      "sm-margin": "8px 0 8px 0px",
    },
  },
  text2: {
    kind: "Text",
    props: {
      margin: "0px 0px 8px 0px",
      font: "normal 500 16px/1.2 --fontFamily-googleMontserrat",
      "letter-spacing": "1px",
      color: "--secondary",
      "text-transform": "uppercase",
      children: (
        <>
          Lets Discuss Ideas, <br />
          Just Say Hello
        </>
      ),
    },
  },
  link: {
    kind: "Link",
    props: {
      href: "mailto:hello@letacom.com",
      color: "--primary",
      "text-decoration-line": "initial",
      font: "--base",
      display: "block",
      margin: "0px 0px 15px 0px",
      children: "hello@letacom.com",
    },
  },
  box16: {
    kind: "Box",
    props: {
      "min-width": "100px",
      "min-height": "100px",
      margin: "8px 0 24px 0px",
      "lg-width": "30%",
      "md-width": "45%",
      "sm-width": "100%",
      "sm-margin": "8px 0 8px 0px",
    },
  },
  text3: {
    kind: "Text",
    props: {
      margin: "0px 0px 20px 0px",
      font: "normal 500 16px/1.2 --fontFamily-googleMontserrat",
      "letter-spacing": "1px",
      color: "--primary",
      "text-transform": "uppercase",
      "sm-margin": "0px 0px 8px 0px",
      children: "South Africa Office",
    },
  },
  text4: {
    kind: "Text",
    props: {
      margin: "0px 0px 15px 0px",
      font: "--base",
      color: "--primary",
      children: (
        <>
          21 Featherbrook Estate, 47 Meadow Ave
          <br />
          Equestria
          <br />
          Pretoria 0184, South Africa
        </>
      ),
    },
  },
  link1: {
    kind: "Link",
    props: {
      href: "tel:+27639746731",
      color: "--primary",
      "text-decoration-line": "initial",
      font: "--base",
      display: "block",
      margin: "0px 0px 15px 0px",
      children: "+27 63 974 6731",
    },
  },
  box17: {
    kind: "Box",
    props: {
      "min-width": "100px",
      "min-height": "100px",
      margin: "8px 0 24px 0px",
      "lg-width": "30%",
      "md-width": "45%",
      "sm-width": "100%",
      "sm-margin": "8px 0 8px 0px",
    },
  },
  text5: {
    kind: "Text",
    props: {
      margin: "0px 0px 20px 0px",
      font: "normal 500 16px/1.2 --fontFamily-googleMontserrat",
      "letter-spacing": "1px",
      color: "--primary",
      "text-transform": "uppercase",
      "sm-margin": "0px 0px 8px 0px",
      children: "Zimbabwe Office",
    },
  },
  text6: {
    kind: "Text",
    props: {
      margin: "0px 0px 15px 0px",
      font: "--base",
      color: "--primary",
      children: (
        <>
          Tunsgate Office Park, 30 Tunsgate Road
          <br /> Mount Pleasant
          <br />
          Harare, Zimbabwe
        </>
      ),
    },
  },
  link2: {
    kind: "Link",
    props: {
      href: "tel:+263778106935",
      color: "--primary",
      "text-decoration-line": "initial",
      font: "--base",
      display: "block",
      margin: "0px 0px 15px 0px",
      children: "+263 77 810 6935",
    },
  },
  text7: {
    kind: "Text",
    props: {
      margin: "0px 8px 0px 0px",
      font: "normal 500 20px/1.2 --fontFamily-googleMontserrat",
      "letter-spacing": "1px",
      color: "--green",
      "text-transform": "uppercase",
      "sm-margin": "0px 0px 8px 0px",
      children: "Submit via WhatsApp",
    },
  },
  icon: {
    kind: "Icon",
    props: {
      category: "fa",
      icon: FaWhatsapp,
      size: "32px",
      color: "--green",
    },
  },
};

const ContactUs = (props) => {
  const { override, children, rest } = useOverrides(
    props,
    overrides,
    defaultProps
  );

  function onSubmit(props) {
    console.log(props);
  }
  return (
    <Section {...rest}>
      <Override slot="SectionContent" max-width="1220px" />
      <Box {...override("box")}>
        <Box {...override("box1")}>
          <Box {...override("box2")}>
            <Text {...override("text")} />
            <QuarklycommunityKitLottie
              {...override("quarklycommunityKitLottie")}
            />
          </Box>
        </Box>
        <Box {...override("box3")}>
          <Box {...override("box4")}>
            <QuarklycommunityKitNetlifyForm
              onSubmit={onSubmit}
              {...override("quarklycommunityKitNetlifyForm")}
            >
              <Box {...override("box5")}>
                <Box {...override("box6")}>
                  <Box {...override("box7")}>
                    <FormLabel {...override("formLabel")} />
                    <Input {...override("input")} />
                  </Box>
                  <Box {...override("box8")}>
                    <FormLabel {...override("formLabel1")} />
                    <Input {...override("input1")} />
                  </Box>
                  <Box {...override("box9")}>
                    <FormLabel {...override("formLabel2")} />
                    <Input {...override("input2")} />
                  </Box>
                  <Box {...override("box10")}>
                    <FormLabel {...override("formLabel3")} />
                    <Input {...override("input3")} />
                  </Box>
                  <Box {...override("box11")}>
                    <FormLabel {...override("formLabel4")} />
                    <LetaSelect {...override("letaSelect")} />
                  </Box>
                  <Box {...override("box12")}>
                    <FormLabel {...override("formLabel5")} />
                    <LetaSelect {...override("letaSelect1")} />
                  </Box>
                  <Box {...override("box13")}>
                    <FormLabel {...override("formLabel6")} />
                    <Input {...override("input4")} />
                    <Text {...override("text1")} />
                  </Box>
                  <Box display="flex" width="100%">
                    <Button {...override("button")}>Submit</Button>
                    <LinkBox {...override("linkBox")}>
                      <Text {...override("text7")} />
                      <Icon {...override("icon")} />
                    </LinkBox>
                  </Box>
                </Box>
              </Box>
            </QuarklycommunityKitNetlifyForm>
          </Box>
          <Box {...override("box14")}>
            <Box {...override("box15")}>
              <Text {...override("text2")} />
              <Link {...override("link")} />
            </Box>
            <Box {...override("box16")}>
              <Text {...override("text3")} />
              <Text {...override("text4")} />
              <Link {...override("link1")} />
            </Box>
            <Box {...override("box17")}>
              <Text {...override("text5")} />
              <Text {...override("text6")} />
              <Link {...override("link2")} />
            </Box>
          </Box>
        </Box>
      </Box>
      {children}
    </Section>
  );
};

Object.assign(ContactUs, { ...Section, defaultProps, overrides });
export default ContactUs;
