export default [
  {
    project: "homelock",
    title: "Homelock Business Card",
    year: "2022",
    description: "Homelock Business Card",
    brand: "Homelock",
    service: "Design",
    images: ["https://i.postimg.cc/3RW8f2Xv/01.jpg"],
  },
  {
    project: "bigcash-mtn",
    title: "Bigcash on MTN VAS Portal",
    year: "2023",
    description: "Bigcash on MTN VAS Portal",
    brand: "MTN",
    service: "Design",
    images: [
      "https://i.postimg.cc/QCxyJq12/1.png",
      "https://i.postimg.cc/3NSbSDZd/2.jpg",
    ],
  },
  {
    project: "khm-business-card",
    title: "KHM Technology Business Card",
    year: "2023",
    description: "KHM Technology Business Card",
    brand: "KHM Technology",
    service: "Design",
    images: [
      "https://i.postimg.cc/TwWwYrKX/KHMTechnology-Business-Card-Design-Mockup.jpg",
      "https://i.postimg.cc/HLNngSs2/Light-KHMTechnology-Business-Card-Design-Mockup.jpg",
    ],
  },
  {
    project: "bigcash-econet",
    title: "Bigcash Econet VAS Portal",
    year: "2023",
    description: "Bigcash Econet VAS Portal",
    brand: "Econet",
    service: "Design",
    images: [
      "https://i.postimg.cc/zvcRPJHJ/01.jpg",
      "https://i.postimg.cc/8kKWCbKq/02.jpg",
    ],
  },

  //   {
  //     project: "",
  //     title: "",
  //     year: "2023",
  //     description: "",
  //     brand: "",
  //     service: "Design",
  //     images: importAll("./../../assets/projects/design/")),
  //   },
];
