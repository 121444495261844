import React, { useState } from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, LinkBox } from "@quarkly/widgets";
const defaultProps = {
  "min-height": "400px",
  transition: "background 0.5s linear 0s",
  "align-items": "flex-end",
  "justify-content": "flex-end",
  href: "/project?q",
  "border-radius": "4px",
};
const overrides = {
  box: {
    kind: "Box",
    props: {
      "min-width": "100px",
      "min-height": "100px",
      display: "flex",
      width: "100%",
      "align-items": "end",
      padding: "0px 0px 16px 0px",
    },
  },
  text: {
    kind: "Text",
    props: {
      margin: "0px 0px 0px 16px",
      color: "--light",
      font: "normal 500 20px/1.5 --fontFamily-googleMontserrat",
      transition: "all 2s ease 0.5s",
    },
  },
};

const ProjectItem = (props) => {
  const [label, setLabel] = useState(false);
  const { override, children, rest } = useOverrides(
    props,
    overrides,
    defaultProps
  );

  const color = props.color ?? "#0271fb";
  const image = props.images[0] ?? "";
  const background = {
    background: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 50%, ${color} 50%, ${color}) 100% 0/100% 200%,rgba(0, 0, 0, 0) url(${image}) 50% 50% /cover no-repeat scroll padding-box`,
    "hover-background": `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 50%, ${color} 50%, ${color}) 100% 100%/100% 200%,rgba(0, 0, 0, 0) url(${image}) 50% 50% /cover no-repeat scroll padding-box`,
    href: `/project/${props.service}/${props.project}`,
  };
  return (
    <LinkBox
      {...{ ...rest, ...background }}
      onMouseOver={() => setLabel(true)}
      onMouseOut={() => setLabel(false)}
    >
      <Box {...override("box")}>
        {label ? (
          <Text
            {...{
              ...override("text"),
              ...{
                children: props.title,
              },
            }}
          />
        ) : undefined}
      </Box>
      {children}
    </LinkBox>
  );
};

Object.assign(ProjectItem, { ...LinkBox, defaultProps, overrides });
export default ProjectItem;
