import React from "react";
import { useOverrides } from "@quarkly/components";
import { Icon, Text, Box } from "@quarkly/widgets";
import { IoIosCheckmark } from "react-icons/io";
const defaultProps = {
	"min-width": "10px",
	"min-height": "10px",
	"display": "flex",
	"align-items": "center",
	"border-width": "0 0 1px 0",
	"border-style": "dashed",
	"border-color": "#bec3ca",
	"margin": "0 0 6px 0"
};
const overrides = {
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoIosCheckmark,
			"size": "32px",
			"color": "--secondary",
			"margin": "0px 8px 0px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 5px 0px",
			"font": "normal 400 18px/1.2 --fontFamily-sansHelvetica",
			"color": "--primary",
			"children": "basic analytics"
		}
	}
};

const TitledIconItem = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const text = override("text");
	text.children = props.title ?? '';
	return <Box {...rest}>
		<Icon {...override("icon")} />
		<Text {...text} />
		{children}
	</Box>;
};

Object.assign(TitledIconItem, { ...Box,
	defaultProps,
	overrides
});
export default TitledIconItem;