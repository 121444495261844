export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"65881d791d712a00232d1bef",
				"65881d791d712a00232d1bf2",
				"6592c5cdfdcae00021dbd7e4",
				"6592c5f38e2e8e00217557f3",
				"6592c680926d910020eaa704",
				"6592c6941d712a00232d6f62",
				"6592c6d18e2e8e002175580d",
				"6592c7408d4a0c002028e76f",
				"6593ff6ffdcae00021dbe9df",
				"6594f1631d712a00232d95c1",
				"6594f576926d910020eac3c2",
				"65a8ce9d009abe001f020efd"
			]
		},
		"65881d791d712a00232d1bef": {
			"id": "65881d791d712a00232d1bef",
			"name": "404",
			"pageUrl": "404"
		},
		"65881d791d712a00232d1bf2": {
			"id": "65881d791d712a00232d1bf2",
			"name": "index",
			"pageUrl": "index",
			"styles": {
				"overflow-x": "hidden"
			}
		},
		"6592c5cdfdcae00021dbd7e4": {
			"id": "6592c5cdfdcae00021dbd7e4",
			"pageUrl": "services",
			"name": "Services",
			"seo": {
				"og:title": "",
				"description": "Explore Letacom's comprehensive service offerings, ranging from Advertising, Digital, Design, and Interactive Services to Mobile Digital Content Solutions, Creative Project Management, and Software Solutions. Elevate your business with tailored solutions, strategic expertise, and innovative approaches for a digital transformation that leaves a lasting impact.",
				"title": "Empowering Success: Letacom's Comprehensive Service Spectrum for Digital Excellence",
				"og:description": "Unlock the power of innovation with Letacom's comprehensive services – from Advertising and Digital Solutions to Design, Interactive Services, Mobile Content Solutions, Creative Project Management, and cutting-edge Software Solutions. Elevate your business with transformative strategies and tailor-made solutions for a digital landscape that defines excellence.",
				"og:image": "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/abstract-shapes-exploding-chaotic-colorful-motion-generated-by-ai.jpg?v=2024-01-19T11:13:19.513Z"
			}
		},
		"6592c5f38e2e8e00217557f3": {
			"id": "6592c5f38e2e8e00217557f3",
			"pageUrl": "about-us",
			"name": "About Us"
		},
		"6592c680926d910020eaa704": {
			"id": "6592c680926d910020eaa704",
			"pageUrl": "projects",
			"name": "Projects"
		},
		"6592c6941d712a00232d6f62": {
			"id": "6592c6941d712a00232d6f62",
			"pageUrl": "faqs",
			"name": "FAQs"
		},
		"6592c6d18e2e8e002175580d": {
			"id": "6592c6d18e2e8e002175580d",
			"pageUrl": "careers",
			"name": "Careers"
		},
		"6592c7408d4a0c002028e76f": {
			"id": "6592c7408d4a0c002028e76f",
			"pageUrl": "project",
			"name": "Project"
		},
		"6593ff6ffdcae00021dbe9df": {
			"id": "6593ff6ffdcae00021dbe9df",
			"pageUrl": "resources",
			"name": "Resources"
		},
		"6594f1631d712a00232d95c1": {
			"id": "6594f1631d712a00232d95c1",
			"pageUrl": "our-tech-stack",
			"name": "Our Tech Stack"
		},
		"6594f576926d910020eac3c2": {
			"id": "6594f576926d910020eac3c2",
			"pageUrl": "how-we-work",
			"name": "How We Work"
		},
		"65a8ce9d009abe001f020efd": {
			"id": "65a8ce9d009abe001f020efd",
			"pageUrl": "contactus",
			"name": "ContactUs"
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {
			"overflow-x": "hidden"
		},
		"seo": {
			"favicon_32px": "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/1%20copy.png?v=2024-01-04T01:13:56.844Z",
			"title": "Letacom",
			"description": "At Letacom, we specialize in harnessing the power of creative strategies to propel your business forward. Through innovative solutions and imaginative approaches, we help our clients increase brand visibility, elevate products/services, and drive profitability. ",
			"og:title": "Letacom: Your Partner in creative strategies",
			"og:description": "At Letacom, we specialize in harnessing the power of creative strategies to propel your business forward. Through innovative solutions and imaginative approaches, we help our clients increase brand visibility, elevate products/services, and drive profitability. ",
			"customSitemapDomain": "https://letacom.com",
			"og:image": "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/1%20copy.png?v=2024-01-04T01:13:56.844Z"
		}
	}
}