import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Link, Box } from "@quarkly/widgets";
const defaultProps = {
	"align-items": "flex-start",
	"margin": "0px 0px 0px 10px",
	"lg-justify-content": "start",
	"lg-align-items": "flex-start",
	"justify-content": "start",
	"display": "grid",
	"lg-flex-direction": "column",
	"lg-margin": "0px 0px 0px 0px",
	"flex-direction": "column",
	"flex-wrap": "wrap",
	"align-content": "start",
	"grid-gap": "8px 0",
	"md-justify-content": "start"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 500 20px/1.5 --fontFamily-sans",
			"color": "--light",
			"children": "Services"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"border-color": "--color-primary",
			"display": "flex",
			"font": "normal 400 18px/1.5 --fontFamily-googleMontserrat",
			"margin": "0px 0 0px 0",
			"lg-border-width": "0px 0px 0px 2px",
			"href": "/services",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "#a78bfa",
			"children": "About"
		}
	}
};
const services = [{
	"value": "advertising",
	"label": "Advertising Services"
}, {
	"value": "digital",
	"label": "Digital Services"
}, {
	"value": "design",
	"label": "Design Services"
}, {
	"value": "interactive",
	"label": "Interactive Services"
}, {
	"value": "mobileDigital",
	"label": "Mobile Digital Content Solutions"
}, {
	"value": "crm",
	"label": "Creative Project Management Services"
}, {
	"value": "sss",
	"label": "Software Solutions Services"
}];

const FooterServices = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Box {...rest}>
		<Text {...override("text")} />
		{services.map(e => {
			const link = { ...override("link"),
				...{
					children: e.label
				}
			};
			return <Link {...link} />;
		})}
		{children}
	</Box>;
};

Object.assign(FooterServices, { ...Box,
	defaultProps,
	overrides
});
export default FooterServices;