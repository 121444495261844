import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Section } from "@quarkly/widgets";
import ProcessItem from "./ProcessItem";
const defaultProps = {
	"padding": "120px 0 130px 0",
	"lg-padding": "80px 0 90px 0",
	"sm-padding": "40px 0 24px 0",
	"quarkly-title": "Stages/Steps-4"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 90px 0px",
			"font": "normal 600 42px/1.2 --fontFamily-googleMontserrat",
			"color": "--darkL1",
			"border-color": "--color-dark",
			"text-align": "center",
			"lg-margin": "0px 0px 50px 0px",
			"children": "How We Work For You"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"grid-template-columns": "repeat(3, 1fr)",
			"md-grid-template-columns": "1fr",
			"md-grid-template-rows": "auto",
			"md-grid-gap": 0,
			"lg-padding": "0px 0 0px 0"
		}
	},
	"processItem": {
		"kind": "ProcessItem",
		"props": {}
	}
};
const items = [{
	index: "1",
	title: "Ideation",
	description: "Getting to know our clients, their goals, values, story, and audience."
}, {
	index: "2",
	title: "Brainstorming",
	description: "Developing a strategy defining the design approach, user experience, and key project features."
}, {
	index: "3",
	title: "Design",
	description: "Collaborating closely with clients, incorporating feedback to meet their needs and vision."
}, {
	index: "4",
	title: "Development",
	description: "Setting up a development roadmap and commencing development after design validation."
}, {
	index: "5",
	title: "Iteration",
	description: "Testing the product with end-users and clients, making necessary changes to enhance user experience."
}]; // {
// 	index:"",
// 	title:"",
// 	description:""
// },

const HowWeWorkItems = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Text {...override("text")} />
		<Box {...override("box")}>
			{items.map(e => {
				const item = { ...override("processItem"),
					...e
				};
				return <ProcessItem {...item} />;
			})}
		</Box>
		{children}
	</Section>;
};

Object.assign(HowWeWorkItems, { ...Section,
	defaultProps,
	overrides
});
export default HowWeWorkItems;