import React from "react";
import { useOverrides } from "@quarkly/components";
import { Box } from "@quarkly/widgets";
import TechStackItem from "./TechStackItem";
const defaultProps = {
	"min-width": "100px",
	"min-height": "100px",
	"padding": "40px 0px 40px 0px",
	"sm-padding": "16px 0px 16px 0px"
};
const overrides = {
	"techStackItem": {
		"kind": "TechStackItem",
		"props": {}
	},
	"techStackItem1": {
		"kind": "TechStackItem",
		"props": {}
	}
};
const items = [{
	title: "Design and Creative Tools",
	images: [{
		title: "Adobe Photoshop",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/ps.png?v=2024-01-03T15:45:28.706Z"
	}, {
		title: "Adobe Illustrator",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/illustrator.png?v=2024-01-03T15:46:28.915Z"
	}, {
		title: "Adobe After Effects",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/Adobe_After_Effects_CC_icon.svg.png?v=2024-01-03T15:48:13.753Z"
	}, {
		title: "Adobe Premier Pro",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/Adobe-premiere-pro-cc-1430-vector-svg-.svg?v=2024-01-03T15:48:59.316Z"
	}, {
		title: "Adobe XD",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/download%20%281%29.png?v=2024-01-03T15:50:59.436Z"
	}, {
		title: "Adobe Indesign",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/Adobe_InDesign_CC_icon.svg.png?v=2024-01-03T15:51:43.831Z"
	}, {
		title: "Adobe Indesign",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/Adobe_InDesign_CC_icon.svg.png?v=2024-01-03T15:51:43.831Z"
	}, {
		title: "Corel Draw",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/CorelDraw_Logo.png?v=2024-01-03T15:53:35.901Z"
	}, {
		title: "Figma",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/figma.webp?v=2024-01-03T15:54:42.132Z"
	}]
}, {
	title: "Frontend Development",
	images: [{
		title: "React",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/React.svg.png?v=2024-01-03T16:24:52.323Z"
	}, {
		title: "Angular",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/angular.png?v=2024-01-03T16:26:47.494Z"
	}, {
		title: "Vue.js",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/Vue.js_Logo_2.svg.png?v=2024-01-03T16:28:30.052Z"
	}, {
		title: "Bootstrap",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/Bootstrap_logo.svg.png?v=2024-01-03T16:29:28.592Z"
	}]
}, {
	title: "Backend Development",
	images: [{
		title: "Node.js",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/2560px-Node.js_logo.svg.png?v=2024-01-03T16:35:50.962Z"
	}, {
		title: "Django",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/Django-Logo.png?v=2024-01-03T16:37:14.876Z"
	}, {
		title: "Express.js",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/Expressjs.png?v=2024-01-03T16:38:17.217Z"
	}, {
		title: "Ruby on Rails",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/rubyy.png?v=2024-01-03T16:41:27.815Z"
	}, {
		title: "Flask",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/flask.png?v=2024-01-03T16:40:16.691Z"
	}]
}, {
	title: "Database Management",
	images: [{
		title: "MongoDB",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/MongoDB_Logo.svg.png?v=2024-01-03T16:45:19.117Z"
	}, {
		title: "MySQL",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/MySQL_textlogo.svg.png?v=2024-01-03T16:46:24.300Z"
	}, {
		title: "PostgreSQL",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/PostgreSQL-Logo.png?v=2024-01-03T16:48:00.277Z"
	}, {
		title: "Firebase",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/1280px-Firebase_Logo.svg.png?v=2024-01-03T16:48:56.236Z"
	}]
}, {
	title: "Content Management Systems (CMS)",
	images: [{
		title: "WordPress",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/WordPress_blue_logo.svg.webp?v=2024-01-03T16:50:02.318Z"
	}, {
		title: "Drupal",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/Wordmark2_blue_RGB%281%29.png?v=2024-01-03T16:51:04.618Z"
	}, {
		title: "Joomla",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/Vertical-logo-light-background-en.png?v=2024-01-03T16:52:05.588Z"
	}]
}, {
	title: "	E-commerce Platforms",
	images: [{
		title: "Shopify",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/Shopify_logo_2018.svg.png?v=2024-01-03T16:53:13.178Z"
	}, {
		title: "Magento",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/magento-1.svg?v=2024-01-03T16:54:14.769Z"
	}, {
		title: "WooCommerce",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/woocommerce-logo.png?v=2024-01-03T16:55:19.188Z"
	}, {
		title: "BigCommerce",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/BigCommerce-logo-dark.webp?v=2024-01-03T16:56:02.898Z"
	}]
}, {
	title: "Cloud Services",
	images: [{
		title: "Amazon Web Services (AWS)",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/1200px-Amazon_Web_Services_Logo.svg.png?v=2024-01-03T16:57:15.472Z"
	}, {
		title: "Microsoft Azure",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/Microsoft_Azure_Logo.svg.png?v=2024-01-03T16:58:34.918Z"
	}, {
		title: "Google Cloud Platform (GCP)",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/Google_Cloud_logo.svg.png?v=2024-01-03T17:00:17.493Z"
	}]
}, {
	title: "	Mobile Development",
	images: [{
		title: "Kotlin",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/Kotlin_logo.svg.png?v=2024-01-03T17:02:10.333Z"
	}, {
		title: "Swift",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/Swift_logo.svg.png?v=2024-01-03T17:03:05.717Z"
	}, {
		title: "Objective-C",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/objective-C-logo.jpg?v=2024-01-03T17:19:23.609Z"
	}]
}, {
	title: "DevOps and Deployment",
	images: [{
		title: "Docker",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/download%20%282%29.png?v=2024-01-03T17:07:35.103Z"
	}, {
		title: "Kubernetes",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/2560px-Kubernetes_logo.svg.png?v=2024-01-03T17:08:28.010Z"
	}, {
		title: "Jenkins",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/Jenkins_logo_with_title.svg.png?v=2024-01-03T17:09:16.460Z"
	}, {
		title: "Git",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/Git-logo.svg.png?v=2024-01-03T17:10:12.231Z"
	}]
}, {
	title: "Other Relevant Technologies",
	images: [{
		title: "GraphQL",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/gql.png?v=2024-01-03T17:11:57.378Z"
	}, {
		title: "Java",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/java.png?v=2024-01-03T17:12:56.980Z"
	}, {
		title: "PHP",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/PHP-logo.svg.png?v=2024-01-03T17:13:43.414Z"
	}, {
		title: ".NET",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/NET-Framework-Logo-2010.png?v=2024-01-03T17:14:38.437Z"
	}, {
		title: "Selenium",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/Selenium_logo.svg.png?v=2024-01-03T17:16:52.422Z"
	}, {
		title: "Apache Kafka",
		url: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/kafka-logo-wide.png?v=2024-01-03T17:16:08.045Z"
	}]
}];

const TechStackItems = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Box {...rest}>
		{items.map(e => {
			const item = { ...override("techStackItem"),
				...e
			};
			return <TechStackItem {...item} />;
		})}
		{children}
	</Box>;
};

Object.assign(TechStackItems, { ...Box,
	defaultProps,
	overrides
});
export default TechStackItems;