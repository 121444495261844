import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Section } from "@quarkly/widgets";
import TeamItem from "./TeamItem";
const defaultProps = {
	"padding": "80px 0 80px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"align-items": "center",
			"display": "flex",
			"justify-content": "center",
			"flex-direction": "column",
			"margin": "0px 0px 32px 0px",
			"width": "100%"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--headline1",
			"color": "--primary",
			"sm-text-align": "center",
			"text-align": "center",
			"children": "Meet Our Team"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "grid",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "32px",
			"md-grid-template-columns": "1fr",
			"width": "100%"
		}
	},
	"teamItem": {
		"kind": "TeamItem",
		"props": {}
	}
};
const team = [{
	src: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/193865638_1301133856955256_7819673603471092415_n.jpg?v=2024-01-03T07:30:11.292Z",
	name: "Comfort Vhurudzai",
	title: "Managing Partner - Business Development",
	description: "Providing strategic direction and overseeing business development initiatives"
}, {
	src: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/193865638_1301133856955256_7819673603471092415_n.jpg?v=2024-01-03T07:30:11.292Z",
	name: "Lewis Makuvaza",
	title: "Managing Partner - Operations",
	description: "Overseeing operational efficiency and ensuring seamless daily operations"
}, {
	src: "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/193865638_1301133856955256_7819673603471092415_n.jpg?v=2024-01-03T07:30:11.292Z",
	name: "Takudzwa Masapa",
	title: "Managing Partner - Technology & Innovation",
	description: "Leading technology initiatives and driving innovation strategies"
}]; // {
// 	src:"",
// 	name:"",
// 	title:"",
// 	description:""
// }

const MeetOurTeam = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Text {...override("text")} />
		</Box>
		<Box {...override("box1")}>
			{team.map(e => {
				const item = { ...override("teamItem"),
					...e
				};
				return <TeamItem {...item} />;
			})}
		</Box>
		{children}
	</Section>;
};

Object.assign(MeetOurTeam, { ...Section,
	defaultProps,
	overrides
});
export default MeetOurTeam;