import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text } from "@quarkly/widgets";
import QuarklycommunityKitTabsFullButton from "./QuarklycommunityKitTabsFullButton";
import QuarklycommunityKitTabsFullHead from "./QuarklycommunityKitTabsFullHead";
import services from "data/projects/services";
const defaultProps = {
  width: "100%",
};
const overrides = {
  quarklycommunityKitTabsFullButton: {
    kind: "QuarklycommunityKitTabsFullButton",
    props: {
      "border-color": "none",
      height: "48px",
      "sm-height": "32px",
      display: "block",
      margin: "0px 8px 0px 0px",
    },
  },
  quarklycommunityKitTabsFullButtonOverride: {
    kind: "Override",
    props: {
      slot: "Tab",
      "border-width": "1px",
      "border-color": "--color-primary",
      "border-radius": "24PX",
      transition: "background-color 0.125s ease-out 0s",
      color: "--primary",
      background: "NONE",
    },
  },
  quarklycommunityKitTabsFullButtonOverride1: {
    kind: "Override",
    props: {
      slot: "Tab :selected",
      "border-style": "none",
      transition: "background-color 0.125s ease-out 0s",
      background: "--color-secondary",
      color: "--light",
    },
  },
  text: {
    kind: "Text",
    props: {
      margin: "0px 0px 0px 0px",
      "sm-font": "normal 500 16px/1.2 --fontFamily-googleMontserrat",
      font: "normal 500 20px/1.2 --fontFamily-googleMontserrat",
      padding: "0px 16px 0px 16px",
      children: "All",
    },
  },
};

const TabsHeader = (props) => {
  const { override, children, rest } = useOverrides(
    props,
    overrides,
    defaultProps
  );
  return (
    <QuarklycommunityKitTabsFullHead {...rest}>
      {services.map((e) => {
        return (
          <QuarklycommunityKitTabsFullButton
            {...{
              ...override("quarklycommunityKitTabsFullButton"),
              ...{
                tabId: e.value,
              },
            }}
          >
            <Override
              {...override("quarklycommunityKitTabsFullButtonOverride")}
            />
            <Override
              {...override("quarklycommunityKitTabsFullButtonOverride1")}
            />
            <Text
              {...{
                ...override("text"),
                ...{
                  children: e.label,
                },
              }}
            />
          </QuarklycommunityKitTabsFullButton>
        );
      })}
      {children}
    </QuarklycommunityKitTabsFullHead>
  );
};

Object.assign(TabsHeader, {
  ...QuarklycommunityKitTabsFullHead,
  defaultProps,
  overrides,
});
export default TabsHeader;
