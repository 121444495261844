import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"projects"} />
		<Helmet>
			<title>
				Letacom
			</title>
			<meta name={"description"} content={"At Letacom, we specialize in harnessing the power of creative strategies to propel your business forward. Through innovative solutions and imaginative approaches, we help our clients increase brand visibility, elevate products/services, and drive profitability. "} />
			<meta property={"og:title"} content={"Letacom: Your Partner in creative strategies"} />
			<meta property={"og:description"} content={"At Letacom, we specialize in harnessing the power of creative strategies to propel your business forward. Through innovative solutions and imaginative approaches, we help our clients increase brand visibility, elevate products/services, and drive profitability. "} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65881d791d712a00232d1bea/images/1%20copy.png?v=2024-01-04T01:13:56.844Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65881d791d712a00232d1bea/images/1%20copy.png?v=2024-01-04T01:13:56.844Z"} type={"image/x-icon"} />
		</Helmet>
		<Components.Header />
		<Components.LetacomHero url="https://uploads.quarkly.io/65881d791d712a00232d1bea/images/abstract-shapes-exploding-chaotic-colorful-motion-generated-by-ai.jpg?v=2024-01-19T11:13:19.513Z">
			<Override slot="text1">
				We create digital excellence to make a lasting impact
			</Override>
			<Override slot="text2">
				At Letacom, we've partnered with startups, SMEs, and large enterprises worldwide, crafting digital products that leave a lasting impact. Our commitment to excellence is mirrored in how selectively we choose our clients — just as carefully as they choose us.
			</Override>
			<Override slot="text">
				OUR WORK
			</Override>
			<Override slot="text3">
				What we have crafted
			</Override>
			<Override slot="icon" size="56px" />
		</Components.LetacomHero>
		<Components.OurWorks>
			<Override slot="tabsHeader">
				<Override slot="quarklycommunityKitTabsFullButton" />
			</Override>
		</Components.OurWorks>
		<Components.LetacomFooter />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65881d791d712a00232d1be8"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});