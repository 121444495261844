import React from "react";
import { transformVar } from "@quarkly/atomize";
import { createGlobalStyle } from "styled-components";

const pageStyles = {
    "404": {
        "overflow-x": "hidden"
    },
    "index": {
        "overflow-x": "hidden"
    },
    "services": {
        "overflow-x": "hidden"
    },
    "about-us": {
        "overflow-x": "hidden"
    },
    "projects": {
        "overflow-x": "hidden"
    },
    "faqs": {
        "overflow-x": "hidden"
    },
    "careers": {
        "overflow-x": "hidden"
    },
    "project": {
        "overflow-x": "hidden"
    },
    "resources": {
        "overflow-x": "hidden"
    },
    "our-tech-stack": {
        "overflow-x": "hidden"
    },
    "how-we-work": {
        "overflow-x": "hidden"
    },
    "contactus": {
        "overflow-x": "hidden"
    }
};

const PageStyles = createGlobalStyle`
    body {
        ${({ styles }) =>
            Object.entries(styles || {}).map(
                ([prop, value]) => `${prop}: ${transformVar(prop, value)};`
            )}
    }
`;
export const GlobalQuarklyPageStyles = ({ pageUrl }) => <PageStyles styles={pageStyles[pageUrl]} />
