import React from "react";
import { useOverrides, Override, StackItem, Stack } from "@quarkly/components";
import { Text, Icon, Box, Section } from "@quarkly/widgets";
import QuarklycommunityKitAnimation from "./QuarklycommunityKitAnimation";
import { MdArrowDownward } from "react-icons/md";
const defaultProps = {
	"background": "linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://images.unsplash.com/photo-1626785774625-ddcddc3445e9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) center/cover",
	"padding": "64px 0",
	"sm-padding": "40px 0",
	"color": "--light",
	"font": "--base"
};
const overrides = {
	"stack": {
		"kind": "Stack",
		"props": {}
	},
	"stackItem": {
		"kind": "StackItem",
		"props": {
			"width": "75%",
			"lg-width": "100%"
		}
	},
	"stackItemOverride": {
		"kind": "Override",
		"props": {
			"slot": "StackItemContent",
			"flex-direction": "column"
		}
	},
	"quarklycommunityKitAnimation": {
		"kind": "QuarklycommunityKitAnimation",
		"props": {
			animation: "↓ Slide In",
			display: "flex",
			"justify-content": "center",
			delay: "5s"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"color": "--secondary",
			"letter-spacing": "1px",
			"text-transform": "uppercase",
			"margin": "0",
			"children": "Careers at Letacom",
			font: "normal 500 20px/1.2 --fontFamily-googleMontserrat"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"font": "--headline1",
			"md-font": "--headline2",
			"margin": "10px 0",
			"children": "Striving for perfection in everything we do. Unparalleled service for everyone."
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 20px/1.5 --fontFamily-sans",
			"children": "Letacom partners with consultants and seeks individuals with passion, expertise, and education in several disciplines. We value creativity, innovation, and diverse skill sets. We encourage candidates with the following backgrounds to consider joining our team"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"text-align": "center",
			"margin": "96px 0 0 0"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "8px 0",
			"text-transform": "uppercase",
			"children": "WHAT WE ARE looking FOR"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			category: "md",
			icon: MdArrowDownward,
			size: "64px",
			color: "--secondary",
			"md-width": "48px",
			"sm-width": "40px"
		}
	}
};

const LetacomHero = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...{ ...rest,
		...{
			background: `linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(${props.url}) center/cover`
		}
	}}>
		<Stack {...override("stack")}>
			<StackItem {...override("stackItem")}>
				<Override {...override("stackItemOverride")} />
				<Text {...override("text")} />
				<Text {...override("text1")} />
				<Text {...override("text2")} />
			</StackItem>
		</Stack>
		<Box {...override("box")}>
			<Text {...override("text3")} />
			<QuarklycommunityKitAnimation {...override("quarklycommunityKitAnimation")}>
				<Icon {...override("icon")} />
			</QuarklycommunityKitAnimation>
		</Box>
		{children}
	</Section>;
};

Object.assign(LetacomHero, { ...Section,
	defaultProps,
	overrides
});
export default LetacomHero;