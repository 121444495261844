import React from "react";
import { useOverrides } from "@quarkly/components";
import { Icon, Text, Strong, Box } from "@quarkly/widgets";
const defaultProps = {
	"padding": "24px 24px 48px 24px",
	"border-radius": "8px",
	"display": "flex",
	"sm-padding": "0px 0px 0px 0px",
	"md-padding": "24px 24px 24px 24px",
	"sm-flex-direction": "column"
};
const overrides = {
	"icon": {
		"kind": "Icon",
		"props": {
			"size": "42px",
			"color": "--light",
			"margin": "0px 24px 0px 0px",
			"background": "--color-secondary",
			"padding": "8px 8px 8px 8px",
			"border-radius": "8px",
			"sm-margin": "0px 0px 12px 0px"
		}
	},
	"box": {
		"kind": "Box",
		"props": {}
	},
	"text": {
		"kind": "Text",
		"props": {
			"font": "normal 700 20px/1.5 --fontFamily-sans",
			"color": "--primary",
			"margin": "0px 0px 0px 0px",
			"letter-spacing": "0.3px",
			"sm-margin": "4px 0px 0px 0px"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "16px 0px 0px 0px",
			"font": "--base",
			"color": "--primary",
			"sm-margin": "6px 0px 0px 0px"
		}
	}
};

const WcuItem = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const icon = override("icon");
	const title = override("text");
	const description = override("text1");
	title.children = props.title;
	description.children = props.description;
	icon.category = props.category;
	icon.icon = props.icon;
	return <Box {...rest}>
		<Icon {...icon} />
		<Box {...override("box")}>
			<Text {...title} />
			<Text {...description} />
		</Box>
		{children}
	</Box>;
};

Object.assign(WcuItem, { ...Box,
	defaultProps,
	overrides
});
export default WcuItem;