import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Span } from "@quarkly/widgets";
const defaultProps = {
	"font": "600 16px/1.3 --fontFamily-googleMontserrat",
	"margin": "0px 0px 8px 0px",
	"sm-margin": "0px",
	"color": "--primary"
};
const overrides = {};

const FormLabel = props => {
	const {
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const r = { ...rest,
		...{}
	};
	return <Text {...r}>
		<>
			{props.label}
			{props.required ? <Span
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				color="--secondary"
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				border-color="--color-secondary"
			>
				*
			</Span> : undefined}
		</>
		{children}
	</Text>;
};

Object.assign(FormLabel, { ...Text,
	defaultProps,
	overrides
});
export default FormLabel;