export default [
  {
    project: "beulabet-web-banners",
    title: "Beulabet Web Banners",
    year: "2023",
    description: "Beulabet Web Banners",
    brand: "Beulabet",
    service: "Digital",
    images: [
      "https://i.postimg.cc/HWX1dLJ9/1.png",
      "https://i.postimg.cc/HsmDxCXD/2.png",
    ],
  },
  {
    project: "bigcash-telkom-web",
    title: "Bigcash on Telkom Web banners",
    year: "2022",
    description: "Bigcash on Telkom Web banners",
    brand: "Telkom",
    service: "Digital",
    images: ["https://i.postimg.cc/hGdpTXRC/Untitled-design.gif"],
  },
  {
    project: "yellowdot-tv",
    title: "Yellowdot TV on Telcom web banner",
    year: "2023",
    description: "Yellowdot TV on Telcom web banner",
    brand: "Yellowdot TV",
    service: "Digital",
    images: ["https://i.postimg.cc/9M1T7208/Untitled-design.png"],
  },
  {
    project: "ydot-games-on-mtn",
    title: "Yellowdot Games on MTN web banner",
    year: "2023",
    description: "Yellowdot Games on MTN web banner",
    brand: "Yellowdot",
    service: "Digital",
    images: [
      "https://i.postimg.cc/TPVnTg6X/1.png",
      "https://i.postimg.cc/G2bGttLT/2.png",
    ],
  },
  //   {
  //     project: "",
  //     title: "",
  //     year: "2023",
  //     description: "",
  //     brand: "",
  //     service: "Digital",
  //     images: importAll(("./../../assets/projects/digital/")),
  //   },
];
