import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box } from "@quarkly/widgets";
const defaultProps = {
	"min-width": "100px",
	"min-height": "100px",
	"display": "flex",
	"flex-direction": "row",
	"padding": "0px 50px 30px 0",
	"sm-padding": "0px 0 34px 0",
	"lg-padding": "0px 25px 0 0",
	"md-margin": "0px 0px 30px 0px",
	"sm-margin": "0px 0px 20px 0px"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "40px",
			"min-height": "40px",
			"background": "--color-secondary",
			"display": "flex",
			"align-items": "center",
			"justify-content": "center",
			"width": "40px",
			"height": "40px",
			"border-radius": "50%",
			"margin": "0px 0px 30px 0px",
			"color": "--darkL2"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--light",
			"font": "--lead",
			"text-align": "center",
			"children": "1"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "0px 0px 0px 0"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0 0 15px 25px",
			"border-color": "--color-primary",
			"color": "--darkL1",
			"font": "normal 500 22px/1.2 --fontFamily-sans",
			"text-align": "left",
			"children": "Proposal"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0 0 0 25px",
			"font": "normal 400 16px/1.5 --fontFamily-sansHelvetica",
			"color": "#65696d",
			"text-align": "left",
			"border-color": "#7a7c7f",
			"children": "Once they understand your needs, they will create a proposal outlining the scope of work, timeline, and cost."
		}
	}
};

const ProcessItem = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const itemIndex = override("text");
	const title = override("text1");
	const description = override("text2");
	itemIndex.children = props.index;
	title.children = props.title;
	description.children = props.description;
	return <Box {...rest}>
		<Box {...override("box")}>
			<Text {...itemIndex} />
		</Box>
		<Box {...override("box1")}>
			<Text {...title} />
			<Text {...description} />
		</Box>
		{children}
	</Box>;
};

Object.assign(ProcessItem, { ...Box,
	defaultProps,
	overrides
});
export default ProcessItem;