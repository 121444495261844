import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (props) => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"project"} />
      <Helmet>
        <title>Letacom</title>
        <meta
          name={"description"}
          content={
            "At Letacom, we specialize in harnessing the power of creative strategies to propel your business forward. Through innovative solutions and imaginative approaches, we help our clients increase brand visibility, elevate products/services, and drive profitability. "
          }
        />
        <meta
          property={"og:title"}
          content={"Letacom: Your Partner in creative strategies"}
        />
        <meta
          property={"og:description"}
          content={
            "At Letacom, we specialize in harnessing the power of creative strategies to propel your business forward. Through innovative solutions and imaginative approaches, we help our clients increase brand visibility, elevate products/services, and drive profitability. "
          }
        />
        <meta
          property={"og:image"}
          content={
            "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/1%20copy.png?v=2024-01-04T01:13:56.844Z"
          }
        />
        <link
          rel={"shortcut icon"}
          href={
            "https://uploads.quarkly.io/65881d791d712a00232d1bea/images/1%20copy.png?v=2024-01-04T01:13:56.844Z"
          }
          type={"image/x-icon"}
        />
      </Helmet>
      <Components.Header />
      <Components.ProjectCarousel
        service={props.match.params.service}
        project={props.match.params.project}
      />
      <Components.ProjectDescription
        service={props.match.params.service}
        project={props.match.params.project}
      >
        <Override
          slot="text1"
          sm-font="normal 300 18px/1.5 --fontFamily-googleMontserrat"
        />
      </Components.ProjectDescription>
      <Components.ProjectContactUs />
      <Components.LetacomFooter />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65881d791d712a00232d1be8"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
